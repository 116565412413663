import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";
import Slider from "react-slick";
import { ArrowLeft, ArrowRight } from "@carbon/icons-react";
import { ErrorBoundary } from "carbon-components-react";
import { connect } from "react-redux";
import ParagraphMedia from "../../../../microsites/ggb/components/video/paragraph-media";

const mapStateToProps = (reduxStore) => ({
  microsite: reduxStore.appStore.microsite,
});

class ParagraphNumberFacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
      slideIndex: 0,
    };

    this.slider = React.createRef();
  }

  render() {
    return;
    const abbrNum = (number, decPlaces) => {
      // 2 decimal places => 100, 3 => 1000, etc
      decPlaces = Math.pow(10, decPlaces);

      // Enumerate number abbreviations
      var abbrev = [" k", " mio", " mrd", " bil"];

      // Go through the array backwards, so we do the largest first
      for (var i = abbrev.length - 1; i >= 0; i--) {
        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10, (i + 1) * 3);

        // If the number is bigger or equal do the abbreviation
        if (size <= number) {
          // Here, we multiply by decPlaces, round, and then divide by decPlaces.
          // This gives us nice rounding to a particular decimal place.
          number = Math.round((number * decPlaces) / size) / decPlaces;

          // Handle special case where we round up to the next abbreviation
          if (number == 1000 && i < abbrev.length - 1) {
            number = 1;
            i++;
          }

          // Add the letter for the abbreviation
          number += abbrev[i];

          break;
        }
      }

      return number;
    };
    const sectionClassNames = classNames({
        "paragraph paragraph-number-facts tbd": true,
      }),
      sliderSettings = {
        dots: true,
        speed: 0,
        nextArrow: <ArrowRight size={48} />,
        prevArrow: <ArrowLeft size={48} />,
        beforeChange: (current, next) => {
          this.setState({ slideIndex: next });
        },
        responsive: [
          {
            breakpoint: 768,
            settings: {
              touchMove: true,
            },
          },
        ],
      };
    if (this.props.microsite) {
      // @todo nil: this is not a valid way to do it. Lets talk about it later.
      return (
        <section className={sectionClassNames}>
          <div className="container">
            <div className="row justify-content-between">
              {this.props.content.fieldZahlenUndFakten &&
                this.props.content.fieldZahlenUndFakten.map((item, index) =>
                  (() => {
                    switch (item.entity.__typename) {
                      case "ParagraphSectionHeading":
                        return (
                          <div className="row headline">
                            <div className="col-16">
                              <h2 className="heading">
                                {item.entity.fieldHeading}
                              </h2>
                            </div>
                          </div>
                        );
                      case "ParagraphZahlUndFakt":
                        return (
                          <div className="col d-flex justify-content-center">
                            <div className={`num-wrap ${index}`}>
                              <div className="number-abbr">
                                {abbrNum(item.entity.fieldZahl, 1)
                                  .split(" ")
                                  .map((part, index) => (
                                    <React.Fragment key={index}>
                                      {index === 0 && (
                                        <CountUp
                                          start={0}
                                          end={+part}
                                          decimals={part.split(".")[1]?.length}
                                          delay={0}
                                          duration={2}
                                          separator="."
                                        >
                                          {({ countUpRef }) => (
                                            <span ref={countUpRef} className="number">{part}</span>
                                          )}
                                        </CountUp>
                                      )}
                                      {index === 1 && <span className="abbr">{part}</span>}
                                    </React.Fragment>
                                  ))}
                              </div>
                              {item.entity.fieldLabel &&
                                <p>{item.entity.fieldLabel}</p>
                              }
                              {item.entity.fieldLink &&
                                <a href={item.entity.fieldLink.url.path} className="btn">
                                  {item.entity.fieldLink.title}
                                </a>
                              }
                            </div>
                          </div>
                        );
                      case "ParagraphText":
                        return (
                          <div className="row subline">
                            <div className="col-16">
                              <div
                                className="text"
                                dangerouslySetInnerHTML={{
                                  __html: item.entity.fieldText.processed,
                                }}
                              />
                            </div>
                          </div>
                        );
                    }
                  })()
                )}
            </div>
          </div>
        </section>
      );
    } else {
      return (
        <section className={sectionClassNames}>
          <div className="container">
            <div className="row">
              <div className="col-16 col-lg-14 offset-lg-2">
                {this.props.content.fieldZahlenUndFakten.length === 1 && (
                  <div className="container">
                    <div className="row">
                      <div className="col d-flex align-items-center justify-content-center">
                        <div className="number-fact main">
                          <div className="label">
                            {
                              this.props.content.fieldZahlenUndFakten[0].entity
                                .fieldLabel
                            }
                          </div>
                          <div>
                            <div className="number">
                              <LazyLoad offset={100}>
                                <CountUp
                                  start={0}
                                  end={
                                    this.props.content.fieldZahlenUndFakten[0]
                                      .entity.fieldZahl
                                  }
                                  delay={0}
                                  duration={2}
                                  separator="."
                                >
                                  {({ countUpRef }) => (
                                    <div className="background-wrapper">
                                      <span ref={countUpRef}>
                                        {
                                          this.props.content
                                            .fieldZahlenUndFakten[0].entity
                                            .fieldZahl
                                        }
                                      </span>
                                    </div>
                                  )}
                                </CountUp>
                              </LazyLoad>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.props.content.fieldZahlenUndFakten.length > 1 && (
                  <LazyLoad offset={100}>
                    <ErrorBoundary>
                      <div className="progress-bar-container">
                        <div className="progress-bar ">
                          {[
                            ...Array(
                              this.props.content.fieldZahlenUndFakten.length
                            ),
                          ].map((item, index) => (
                            <button
                              className="dot d-lg-flex d-none"
                              key={index}
                              onClick={() =>
                                this.slider.current.slickGoTo(index)
                              }
                            >
                              {index}
                            </button>
                          ))}
                        </div>
                        <div
                          className="progress-slider d-lg-flex d-none"
                          style={{
                            width: `${
                              (1 /
                                this.props.content.fieldZahlenUndFakten
                                  .length) *
                              100
                            }%`,
                            marginLeft: `${
                              (this.state.slideIndex /
                                this.props.content.fieldZahlenUndFakten
                                  .length) *
                              100
                            }%`,
                          }}
                        ></div>
                      </div>
                      <Slider {...sliderSettings} ref={this.slider}>
                        {this.props.content.fieldZahlenUndFakten.map(
                          (item, index) => (
                            <article
                              className="custom-slide-wrapper"
                              key={index}
                            >
                              <div className="container">
                                <div className="row">
                                  <div className="col-16 col-lg-8 d-flex align-items-center">
                                    <div className="span-wrap">
                                      {item.entity.fieldLabel &&
                                        item.entity.fieldLabel}
                                    </div>
                                  </div>
                                  <div className="col-16 col-lg-8 d-flex align-items-center">
                                    <div className="number">
                                      {this.state.slideIndex === index && (
                                        <CountUp
                                          start={0}
                                          end={item.entity.fieldZahl}
                                          delay={0}
                                          duration={2}
                                          separator="."
                                        >
                                          {({ countUpRef }) => (
                                            <div className="background-wrapper">
                                              <div
                                                className="count-up-value"
                                                ref={countUpRef}
                                                key={index}
                                              >
                                                {item.entity.fieldZahl}
                                              </div>
                                            </div>
                                          )}
                                        </CountUp>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </article>
                          )
                        )}
                      </Slider>
                    </ErrorBoundary>
                  </LazyLoad>
                )}
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}

ParagraphNumberFacts.propTypes = {
  content: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ParagraphNumberFacts);
