import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserBase from "../../../teaser-base/teaser-base";
import ErrorBoundary from "../../../../error-boundary";
import { teaserPersonPropTypes } from "../../../teaser-base/person/teaser-person";

const ParagraphKontakt = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-kontakt": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16">
            <div className="row justify-content-center">
              {content.fieldPersonen.map((item, index) => (
                <ErrorBoundary key={index}>
                  <TeaserBase
                    showImage={content.fieldBilderZeigen}
                    item={item.entity}
                    viewMode={"kontakt"}
                  />
                </ErrorBoundary>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphKontakt.propTypes = {
  content: PropTypes.shape({
    fieldBilderZeigen: PropTypes.bool,
    fieldPersonen: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserPersonPropTypes,
      })
    ),
  }),
};

export default ParagraphKontakt;
