import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ThemeWrapper from "@general-components/theme-wrapper/theme-wrapper";
import ContentBase from "@general-components/content-base/content-base";
import ParagraphSwitch from "@general-components/content-base/paragraph-switch";
import ErrorBoundary from "@js/error-boundary";

const ParagraphIntro = ({ content, nodeContent }) => {
  const theme = content.fieldTheme?.entity?.fieldMachineName || "theme-default";

  useEffect(() => {
    document.body.dataset.theme = theme;

    return () => {
      delete document.body.dataset.theme;
    };
  }, []);

  return (
    <ThemeWrapper theme={content.fieldTheme}>
      <section className="paragraph paragraph-intro">
        <div className="container">
          {content.fieldModules &&
            content.fieldModules.map((item) => (
              <ErrorBoundary key={item.entity.entityId}>
                <ParagraphSwitch paragraphItem={item.entity} nodeContent={nodeContent} />
              </ErrorBoundary>
            ))}
        </div>
      </section>
    </ThemeWrapper>
  );
};

ParagraphIntro.propTypes = {
  content: PropTypes.shape({
    fieldTitle: PropTypes.string,
    fieldTheme: PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
        fieldMachineName: PropTypes.string,
      }),
    }),
    fieldModules: PropTypes.object,
  }),
};

export default ParagraphIntro;
