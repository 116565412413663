import React from "react";
import PropTypes from "prop-types";
import DownloadAppstore from "./download_appstore.svg";
import DownloadPlaystore from "./download_playstore.svg";
import Hyphenate from "@general-components/hyphenate/hyphenate";

const ParagraphAppDownloadSection = ({ content }) => {
  return (
    <section className="paragraph paragraph-app-download-section">
      <div className="container">
        <div className="card theme-1 ">
          <div className="card-body text-center">
            <h2 className="headline-xl">{content.fieldTitle}</h2>
            <div
              className="text text-xl"
              dangerouslySetInnerHTML={{ __html: content.fieldText?.processed }}
            />
            <div className="download-links-wrapper d-flex flex-column flex-lg-row justify-content-center align-items-center">
              {content.fieldLinkPlayStore?.url?.path && (
                <a
                  href={content.fieldLinkPlayStore?.url?.path}
                  title="Download App (Google Play)"
                >
                  <DownloadPlaystore />
                </a>
              )}
              {content.fieldLinkAppStore?.url?.path && (
                <a
                  href={content.fieldLinkAppStore?.url?.path}
                  title="Download App (Apple App Store)"
                >
                  <DownloadAppstore />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphAppDownloadSection.propTypes = {};

export default ParagraphAppDownloadSection;
