import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "motion/react";
import { Link } from "react-router-dom";

const DURATION = 8000;

const ParagraphTeaserSlider = ({ content }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(
        (prevSlide) => (prevSlide + 1) % content.fieldTeaserSlides.length
      );
    }, DURATION);

    return () => clearInterval(interval);
  }, []);

  const slide = content.fieldTeaserSlides[currentSlide];

  return (
    <section className="paragraph paragraph-teaser-slider">
      <div className="container">
        <AnimatePresence mode="wait">
          <motion.div
            className="text text-center"
            key={slide.entity.entityId}
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -200 }}
          >
            <h2>{slide.entity.fieldTeaserueberschrift}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: slide.entity.fieldAusfuehrlicherText?.processed,
              }}
            />
          </motion.div>
        </AnimatePresence>
      </div>
    </section>
  );
};

ParagraphTeaserSlider.propTypes = {
  content: PropTypes.shape({
    fieldTeaserSlides: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldTeaserueberschrift: PropTypes.string.isRequired,
          fieldAusfuehrlicherText: PropTypes.shape({
            processed: PropTypes.string,
          }),
          fieldImage: PropTypes.shape({
            entity: PropTypes.shape({
              fieldMediaImage: PropTypes.shape({
                alt: PropTypes.string,
                title: PropTypes.string,
                style: PropTypes.shape({
                  url: PropTypes.string.isRequired,
                }),
              }),
            }),
          }),
          fieldInhaltLink: PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.shape({
              routed: PropTypes.bool,
              path: PropTypes.string,
              entity: PropTypes.shape({
                title: PropTypes.string,
              }),
            }),
          }),
        }),
      })
    ),
  }),
};

export default ParagraphTeaserSlider;
