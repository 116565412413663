import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link as ReactRouterLink } from "react-router-dom";

import Link from "./link/link";
import { ArrowLeft, ArrowRight } from "@carbon/icons-react";
class PagerFullPage extends Component {
  state = {
    prevItem: {},
    nextItem: {},
  };

  getItems = () => {
    // Get previous and next links.
    const activeItemPosition = this.props.config.items.findIndex(
      (item) => item.entityId === this.props.currentId
    );

    if (activeItemPosition !== -1) {
      this.setState({
        prevItem:
          activeItemPosition > 0
            ? this.props.config.items[activeItemPosition - 1]
            : {},
        nextItem:
          activeItemPosition < this.props.config.items.length - 1
            ? this.props.config.items[activeItemPosition + 1]
            : {},
      });
    } else {
      this.setState({
        prevItem: {},
        nextItem: {},
      });
    }
  };

  componentDidMount() {
    this.getItems();
  }

  componentDidUpdate(prevProps) {
    // Reset next and prev links.
    /*if (Object.keys(this.state.nextItem).length !== 0) {
      this.setState({ nextItem: {} });
    }
    if (Object.keys(this.state.prevItem).length !== 0) {
      this.setState({ prevItem: {} });
    }*/

    if (prevProps.currentId !== this.props.currentId) {
      this.getItems();
    }
  }

  render() {
    return (
      <div id="pager-full-page" className="container">
        <div className="row align-items-center">
          <div className="col-16 col-md-4 d-flex justify-content-center justify-content-md-start">
            {this.state.prevItem.path && (
              <ReactRouterLink className="previous" to={this.state.prevItem.path.alias}>
                <ArrowLeft />
                <span>{this.state.prevItem.title}</span>
              </ReactRouterLink>
            )}
          </div>

          <div className="col-16 col-md-8">
            {this.props.config.overviewLink &&
              (Object.keys(this.state.nextItem).length > 0 ||
                Object.keys(this.state.prevItem).length > 0) && (
                <div className="container call-to-action">
                  <div className="row">
                    <div className="col-16 d-flex justify-content-center">
                      <Link
                        className="btn btn-primary"
                        link={this.props.config.overviewLink}
                      />
                    </div>
                  </div>
                </div>
              )}
          </div>

          <div className="col-16 col-md-4 d-flex justify-content-center justify-content-md-end">
            {this.state.nextItem.path && (
              <Link className="next" link={this.state.nextItem}>
                <span>{this.state.nextItem.title}</span>
                <ArrowRight />
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PagerFullPage.propTypes = {
  config: PropTypes.object.isRequired,
  currentId: PropTypes.string,
};

export default PagerFullPage;
