import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";

/**
 * A module representing a hero-text paragraph.
 *
 * @todo In CS we have a field "fieldHintWithColouredBackgro" but we do not use it?
 */
const mapStateToProps = (reduxStore) => ({
  microsite: reduxStore.appStore.microsite,
});
class ParagraphHeroText extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-hero-text": true,
    });
    if (this.props.microsite) {
      return (
        <section className={sectionClassNames}>
          <div className="container">
            <div className="row">
              <div className="col-16 col-md-12 offset-md-2 col-xl-8 offset-xl-4">
                <div
                  className="hero text"
                  dangerouslySetInnerHTML={{
                    __html: this.props.content.fieldText.processed,
                  }}
                />
              </div>
            </div>
          </div>

          {this.props.content.fieldHint && (
            <div className="hint text">
              <div className="container">
                <div className="row">
                  <div className="col-16 col-md-12 offset-md-2 col-xl-8 offset-xl-4">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.content.fieldHint.processed,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      );
    } else {
      return (
        <section className={sectionClassNames}>
          {this.props.content.fieldText && (
            <div className="container">
              <div className="row">
                <div className="col-16 col-lg-12 offset-lg-2">
                  <div
                    className="hero text"
                    dangerouslySetInnerHTML={{
                      __html: this.props.content.fieldText.processed,
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {this.props.content.fieldHint && (
            <div className="hint text">
              <div className="container">
                <div className="row">
                  <div className="col-16">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.content.fieldHint.processed,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      );
    }
  }
}

ParagraphHeroText.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldText: PropTypes.shape({
      processed: PropTypes.string.isRequired,
    }),
    fieldHint: PropTypes.shape({
      processed: PropTypes.string,
    }),
  }).isRequired,
};

export default connect(mapStateToProps)(ParagraphHeroText);
