class Error {
  constructor({ message, status }) {
    this.message = message;
    this.status = status;

    return new Response(message, { status });
  }
}

class NotFoundError extends Error {
  constructor() {
    super({ message: "not_found", status: 404 });
  }
}

class ForbiddenError extends Error {
  constructor() {
    super({ message: "forbidden", status: 403 });
  }
}

class NotAuthorizedError extends Error {
  constructor() {
    super({ message: "not_authorized", status: 401 });
  }
}

class NetworkError extends Error {
  constructor() {
    super({ message: "network", status: 503 });
  }
}

export {
  NotFoundError,
  ForbiddenError,
  NotAuthorizedError,
  NetworkError,
};
