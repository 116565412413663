import React from "react";
import PropTypes from "prop-types";

const ParagraphPageTitle = (props) => {
  return (
    <section className="paragraph paragraph-page-title">
      <h1>{props.content.fieldHeading}</h1>
    </section>
  );
};

ParagraphPageTitle.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
  }),
};

export default ParagraphPageTitle;
