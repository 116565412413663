import PropTypes from "prop-types";
import React from "react";

const KeyVisualMask = ({ imagePath }) => {
  const randomVariant = Math.floor(Math.random() * 2);

  switch (randomVariant) {
    case 0:
      return (
        <>
          <svg
            viewBox="0 0 1306 541"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="d-none d-lg-block"
          >
            {/* prettier-ignore */}
            <>
              <path d="M588.304 263.385H641.315C660.866 263.385 676.716 247.465 676.716 227.827V174.583C676.716 154.945 660.866 139.025 641.315 139.025L588.304 139.025C568.752 139.025 552.903 154.945 552.903 174.583V227.827C552.903 247.465 568.752 263.385 588.304 263.385Z" fill="var(--c-key-visual)"/>
              <path d="M538.552 312.628V365.873C538.552 385.511 522.703 401.43 503.151 401.43H450.141C430.589 401.43 414.74 385.511 414.74 365.873V312.628C414.74 292.991 430.589 277.071 450.141 277.071H503.151C522.703 277.071 538.552 292.991 538.552 312.628Z" fill="var(--c-key-visual)"/>
              <path d="M450.694 124.975H503.705C523.257 124.975 539.106 109.056 539.106 89.4182V36.1734C539.106 16.5357 523.257 0.616184 503.705 0.616183L450.694 0.61618C431.143 0.61618 415.293 16.5357 415.293 36.1734V89.4182C415.293 109.056 431.143 124.975 450.694 124.975Z" fill="var(--c-key-visual)"/>
              <path d="M503.705 263.385H450.694C431.321 263.385 415.582 247.754 415.297 228.363C415.296 228.276 415.295 228.188 415.294 228.1C415.293 227.979 415.292 227.857 415.292 227.736V174.446C415.292 154.757 431.136 138.888 450.648 138.888H517.104C536.887 138.888 552.867 122.838 552.867 102.968V36.2182C552.867 16.5754 568.757 0.661116 588.268 0.661116L641.324 0.661119C660.881 0.66112 676.725 16.6209 676.725 36.2182V89.5085C676.725 109.151 660.835 125.066 641.324 125.066H574.868C555.085 125.066 539.105 141.116 539.105 160.987V174.259C539.106 174.367 539.106 174.475 539.106 174.583V227.827C539.106 247.465 523.257 263.385 503.705 263.385Z" fill="var(--c-key-visual)"/>
              <path d="M539.06 450.809V504.054C539.06 523.692 523.211 539.611 503.659 539.611H450.648C431.097 539.611 415.247 523.692 415.247 504.054V450.809C415.247 431.171 431.097 415.252 450.648 415.252H503.659C523.211 415.252 539.06 431.171 539.06 450.809Z" fill="var(--c-key-visual)"/>
              <path d="M399.642 450.809V504.054C399.642 523.692 383.792 539.611 364.241 539.611L311.23 539.611C291.678 539.611 275.829 523.692 275.829 504.054V450.809C275.829 431.171 291.678 415.252 311.23 415.252H364.241C383.792 415.252 399.642 431.171 399.642 450.809Z" fill="var(--c-key-visual)"/>
              <path d="M364.241 263.385H311.23C291.678 263.385 275.829 247.465 275.829 227.827V174.583C275.829 154.945 291.678 139.025 311.23 139.025H364.241C383.792 139.025 399.642 154.945 399.642 174.583V227.827C399.642 247.465 383.792 263.385 364.241 263.385Z" fill="var(--c-key-visual)"/>
              <path d="M261.478 365.873V312.628C261.478 292.991 245.629 277.071 226.077 277.071H173.066C153.515 277.071 137.665 292.991 137.665 312.628V365.873C137.665 385.511 153.515 401.43 173.066 401.43H226.077C245.629 401.43 261.478 385.511 261.478 365.873Z" fill="var(--c-key-visual)"/>
              <path d="M173.021 539.658H226.077C245.589 539.658 261.433 523.789 261.433 504.146V450.81C261.433 431.213 245.634 415.299 226.077 415.299H159.621C139.838 415.299 123.858 399.248 123.858 379.378V312.628C123.858 293.031 108.014 277.071 88.4572 277.071H35.401C15.8897 277.071 3.18801e-06 292.986 2.32939e-06 312.628L0 365.919C-8.56627e-07 385.516 15.8444 401.476 35.401 401.476H101.857C121.64 401.476 137.62 417.527 137.62 437.397V504.146C137.62 523.743 153.419 539.658 173.021 539.658Z" fill="var(--c-key-visual)"/>
              <path d="M363.652 401.43H310.596C290.994 401.43 275.195 385.516 275.195 365.918V299.169C275.195 279.299 259.215 263.248 239.432 263.248H172.976C153.419 263.248 137.575 247.288 137.575 227.691L137.575 174.401C137.575 154.758 153.464 138.844 172.976 138.844H226.032C226.631 138.844 227.227 138.859 227.819 138.888H239.704C259.487 138.888 275.467 122.837 275.467 102.967V36.218C275.467 16.5752 291.357 0.660861 310.868 0.660862L363.924 0.660864C383.481 0.660865 399.325 16.6206 399.325 36.218V89.5083C399.325 109.151 383.436 125.065 363.924 125.065H297.468C277.685 125.065 261.705 141.116 261.705 160.986V227.736C261.705 229.237 261.613 230.717 261.433 232.169V241.15C261.433 261.02 277.413 277.071 297.196 277.071H363.652C383.209 277.071 399.008 292.985 399.008 312.583V365.918C399.008 385.561 383.163 401.43 363.652 401.43Z" fill="var(--c-key-visual)"/>
              <path d="M88.5028 124.975L35.4918 124.975C15.9404 124.975 0.090801 109.056 0.0908019 89.4182L0.0908042 36.1734C0.0908051 16.5357 15.9404 0.616197 35.4918 0.616198L88.5028 0.6162C108.054 0.616201 123.904 16.5357 123.904 36.1734V89.4182C123.904 109.056 108.054 124.975 88.5028 124.975Z" fill="var(--c-key-visual)"/>
              <path d="M35.4921 263.384H88.503C108.054 263.384 123.904 247.465 123.904 227.827V174.582C123.904 174.47 123.903 174.358 123.902 174.245V160.986C123.902 141.116 139.883 125.065 159.666 125.065H226.122C245.633 125.065 261.523 109.151 261.523 89.5083V36.218C261.523 16.6206 245.678 0.660865 226.122 0.660864L173.065 0.660862C153.554 0.660861 137.664 16.5752 137.664 36.218V102.967C137.664 122.837 121.684 138.888 101.901 138.888H35.4453C15.934 138.888 0.0895173 154.757 0.0895164 174.445L0.0895141 227.736C0.0895141 227.961 0.0915893 228.186 0.0957702 228.411C0.406072 247.779 16.1348 263.384 35.4921 263.384Z" fill="var(--c-key-visual)"/>
              <path d="M123.858 450.809L123.858 504.054C123.858 523.692 108.008 539.611 88.4571 539.611H35.4461C15.8947 539.611 0.0451286 523.692 0.0451295 504.054L0.0451318 450.809C0.0451326 431.171 15.8947 415.252 35.4461 415.252H88.4571C108.008 415.252 123.858 431.171 123.858 450.809Z" fill="var(--c-key-visual)"/>
              <path d="M1027.94 28.6162C1027.94 13.1522 1015.4 0.616211 999.94 0.616211H720.004C704.54 0.616211 692.004 13.1523 692.004 28.6162V249.418C692.004 264.882 679.468 277.418 664.004 277.418H585.861C568.741 277.418 554.861 291.297 554.861 308.418V509.38C554.861 526.5 568.74 540.38 585.861 540.38H996.94C996.991 540.38 997.042 540.38 997.093 540.379H999.94C1015.4 540.379 1027.94 527.843 1027.94 512.379L1027.94 28.6162Z" fill="url(#imagePattern)"/>
              <path d="M1305.34 504.056V450.811C1305.34 431.173 1289.49 415.254 1269.94 415.254H1216.93C1197.38 415.254 1181.53 431.173 1181.53 450.811V504.056C1181.53 523.694 1197.38 539.613 1216.93 539.613H1269.94C1289.49 539.613 1305.34 523.694 1305.34 504.056Z" fill="var(--c-key-visual)"/>
              <path d="M1216.93 263.385H1269.94C1289.49 263.385 1305.34 247.465 1305.34 227.828V174.583C1305.34 154.945 1289.49 139.026 1269.94 139.026L1216.93 139.026C1197.38 139.026 1181.53 154.945 1181.53 174.583V227.828C1181.53 247.465 1197.38 263.385 1216.93 263.385Z" fill="var(--c-key-visual)"/>
              <path d="M1167.18 312.628L1167.18 365.873C1167.18 385.511 1151.33 401.43 1131.78 401.43H1078.77C1059.22 401.43 1043.37 385.511 1043.37 365.873L1043.37 312.628C1043.37 292.991 1059.22 277.071 1078.77 277.071H1131.78C1151.33 277.071 1167.18 292.991 1167.18 312.628Z" fill="var(--c-key-visual)"/>
              <path d="M1269.35 401.431H1216.3C1196.7 401.431 1180.9 385.517 1180.9 365.919V299.17C1180.9 279.3 1164.92 263.249 1145.13 263.249H1078.68C1059.12 263.249 1043.28 247.29 1043.28 227.692L1043.28 174.402C1043.28 154.759 1059.17 138.845 1078.68 138.845H1131.73C1132.33 138.845 1132.92 138.859 1133.51 138.889H1145.41C1165.19 138.889 1181.17 122.838 1181.17 102.968V36.2185C1181.17 16.5756 1197.06 0.661333 1216.57 0.661334L1269.63 0.661336C1289.18 0.661337 1305.03 16.6211 1305.03 36.2185V89.5087C1305.03 109.152 1289.14 125.066 1269.63 125.066H1203.17C1183.39 125.066 1167.41 141.117 1167.41 160.987L1167.41 227.736C1167.41 229.238 1167.32 230.718 1167.14 232.17V241.151C1167.14 261.021 1183.12 277.072 1202.9 277.072H1269.35C1288.91 277.072 1304.71 292.986 1304.71 312.584V365.919C1304.71 385.562 1288.87 401.431 1269.35 401.431Z" fill="var(--c-key-visual)"/>
              <path d="M1131.41 124.974H1078.4C1058.85 124.974 1043 109.055 1043 89.4172L1043 36.1724C1043 16.5348 1058.85 0.615234 1078.4 0.615234L1131.41 0.615237C1150.96 0.615238 1166.81 16.5348 1166.81 36.1724V89.4172C1166.81 109.055 1150.96 124.974 1131.41 124.974Z" fill="var(--c-key-visual)"/>
              <path d="M1078.4 539.993H1131.41C1150.96 539.993 1166.81 524.074 1166.81 504.436V451.191C1166.81 431.554 1150.96 415.634 1131.41 415.634H1078.4C1058.85 415.634 1043 431.554 1043 451.191L1043 504.436C1043 524.074 1058.85 539.993 1078.4 539.993Z" fill="var(--c-key-visual)"/>
            </>
            <defs>
              <pattern
                id="imagePattern"
                patternUnits="userSpaceOnUse"
                width="100%"
                height="100%"
              >
                <image
                  href={imagePath}
                  x="40%"
                  y="0"
                  width="50%"
                  height="100%"
                  preserveAspectRatio="xMidYMid slice"
                ></image>
              </pattern>
            </defs>
          </svg>
          <svg
            viewBox="0 0 357 315"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="d-block d-lg-none"
          >
            {/* prettier-ignore */}
            <>
              <path d="M356.678 182.156V213.205C356.678 224.676 347.447 233.922 336.079 233.922L297.359 233.922C285.833 233.922 276.523 243.273 276.523 254.85V293.74C276.523 305.185 267.265 314.457 255.897 314.457H224.985C213.591 314.457 204.359 305.158 204.359 293.74L204.359 262.692C204.359 251.247 213.617 241.975 224.985 241.975H263.704C275.23 241.975 284.541 232.623 284.541 221.046V182.156C284.541 170.712 293.772 161.466 305.14 161.466H336.079C347.473 161.466 356.678 170.738 356.678 182.156Z" fill="var(--c-key-visual)"/>
              <path d="M255.876 161.386H224.99C213.599 161.386 204.365 170.661 204.365 182.102L204.365 213.124C204.365 224.566 213.599 233.841 224.99 233.841H255.876C267.267 233.841 276.502 224.566 276.502 213.124V182.102C276.502 170.661 267.267 161.386 255.876 161.386Z" fill="var(--c-key-visual)"/>
              <path d="M284.863 101.673V132.695C284.863 144.136 294.097 153.411 305.488 153.411H336.374C347.765 153.411 357 144.136 357 132.695V101.673C357 90.2311 347.765 80.956 336.374 80.956H305.488C294.097 80.956 284.863 90.2311 284.863 101.673Z" fill="var(--c-key-visual)"/>
              <path d="M305.166 242.027L336.052 242.027C347.443 242.027 356.677 251.302 356.677 262.744V293.766C356.677 305.207 347.443 314.482 336.052 314.482H305.166C293.775 314.482 284.54 305.207 284.54 293.766V262.744C284.54 251.302 293.775 242.027 305.166 242.027Z" fill="var(--c-key-visual)"/>
              <path d="M284.567 52.1864V21.1644C284.567 9.72284 293.801 0.447662 305.193 0.447662L336.078 0.447663C347.47 0.447663 356.704 9.72284 356.704 21.1644V52.1864C356.704 63.6279 347.47 72.9031 336.078 72.9031H305.193C293.801 72.9031 284.567 63.6279 284.567 52.1864Z" fill="var(--c-key-visual)"/>
              <path d="M8.21626e-06 286.483C8.7078e-06 301.947 12.536 314.483 28 314.483H167.727C183.191 314.483 195.727 301.947 195.727 286.483V181.21C195.727 165.746 208.263 153.21 223.727 153.21H247.63C263.094 153.21 275.63 140.674 275.63 125.21V28.0001C275.63 12.5361 263.094 0.000125964 247.63 0.00010283L28 3.05176e-05C12.536 3.10091e-05 -4.91546e-07 12.5361 0 28L8.21626e-06 286.483Z" fill="url(#imagePatternMobile)"/>
            </>
            <defs>
              <pattern
                id="imagePatternMobile"
                patternUnits="userSpaceOnUse"
                width="100%"
                height="100%"
              >
                <image
                  href={imagePath}
                  x="0"
                  y="0"
                  width="80%"
                  height="100%"
                  preserveAspectRatio="xMidYMid slice"
                ></image>
              </pattern>
            </defs>
          </svg>
        </>
      );
    case 1:
      return (
        <>
          <svg
            viewBox="0 0 1310 542"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="d-none d-lg-block"
          >
            {/* prettier-ignore */}
            <>
              <path d="M401.33 451.572V504.98C401.33 524.678 385.432 540.646 365.82 540.646H312.647C293.036 540.646 277.138 524.678 277.138 504.98V451.572C277.138 431.874 293.036 415.906 312.647 415.906H365.82C385.432 415.906 401.33 431.874 401.33 451.572Z" fill="var(--c-key-visual)"/>
              <path d="M262.743 312.968V366.376C262.743 386.074 246.845 402.042 227.234 402.042H174.06C154.449 402.042 138.551 386.074 138.551 366.376V312.968C138.551 293.27 154.449 277.302 174.06 277.302H227.234C246.845 277.302 262.743 293.27 262.743 312.968Z" fill="var(--c-key-visual)"/>
              <path d="M227.234 540.693H174.015C154.353 540.693 138.506 524.73 138.506 505.072V438.119C138.506 418.187 122.477 402.088 102.633 402.088H35.9735C16.357 402.088 0.46411 386.079 0.464111 366.422L0.464114 312.968C0.464115 293.265 16.4024 277.302 35.9735 277.302H89.1922C108.809 277.302 124.702 293.311 124.702 312.968V379.922C124.702 399.853 140.731 415.953 160.574 415.953H227.234C246.85 415.953 262.698 431.916 262.698 451.573V505.072C262.698 524.775 246.805 540.693 227.234 540.693Z" fill="var(--c-key-visual)"/>
              <path d="M312.011 402.042H365.23C384.801 402.042 400.694 386.124 400.694 366.421V312.922C400.694 293.265 384.846 277.302 365.23 277.302H298.57C278.727 277.302 262.698 261.202 262.698 241.271V174.317C262.698 154.66 246.805 138.651 227.188 138.651H173.969C154.398 138.651 138.46 154.614 138.46 174.317L138.46 227.771C138.46 247.428 154.353 263.437 173.969 263.437H240.629C260.473 263.437 276.502 279.537 276.502 299.468V366.421C276.502 386.079 292.349 402.042 312.011 402.042Z" fill="var(--c-key-visual)"/>
              <path d="M36.0646 124.74H89.2379C108.849 124.74 124.747 108.772 124.747 89.074V35.6661C124.747 15.9683 108.849 3.18152e-06 89.2379 2.32428e-06L36.0647 0C16.4534 -8.57237e-07 0.555191 15.9683 0.55519 35.6661L0.555188 89.074C0.555187 108.772 16.4534 124.74 36.0646 124.74Z" fill="var(--c-key-visual)"/>
              <path d="M89.2382 263.573H36.0649C16.6436 263.573 0.863897 247.913 0.559912 228.479C0.555914 228.258 0.5539 228.037 0.5539 227.815L0.553902 174.362C0.553903 154.613 16.4469 138.696 36.0179 138.696L102.677 138.696C122.521 138.696 138.55 122.596 138.55 102.665V35.7111C138.55 16.0081 154.489 0.0450588 174.06 0.0450596L227.278 0.0450619C246.895 0.0450628 262.788 16.0537 262.788 35.7111V89.1646C262.788 108.868 246.849 124.831 227.278 124.831H160.619C140.775 124.831 124.746 140.931 124.746 160.862V174.168C124.747 174.228 124.747 174.287 124.747 174.346C124.747 174.397 124.748 174.448 124.748 174.499V227.907C124.748 247.605 108.849 263.573 89.2382 263.573Z" fill="var(--c-key-visual)"/>
              <path d="M124.701 451.572L124.701 504.98C124.701 524.678 108.803 540.646 89.192 540.646H36.0187C16.4074 540.646 0.50927 524.678 0.509271 504.98L0.509274 451.572C0.509274 431.874 16.4074 415.906 36.0187 415.906H89.192C108.803 415.906 124.701 431.874 124.701 451.572Z" fill="var(--c-key-visual)"/>
              <path d="M1310 504.981V451.573C1310 431.875 1294.1 415.907 1274.49 415.907H1221.32C1201.71 415.907 1185.81 431.875 1185.81 451.573V504.981C1185.81 524.679 1201.71 540.647 1221.32 540.647H1274.49C1294.1 540.647 1310 524.679 1310 504.981Z" fill="var(--c-key-visual)"/>
              <path d="M1221.32 263.573H1274.49C1294.1 263.573 1310 247.605 1310 227.907V174.499C1310 154.801 1294.1 138.833 1274.49 138.833L1221.32 138.833C1201.71 138.833 1185.81 154.801 1185.81 174.499V227.907C1185.81 247.605 1201.71 263.573 1221.32 263.573Z" fill="var(--c-key-visual)"/>
              <path d="M1171.41 312.968L1171.41 366.376C1171.41 386.074 1155.52 402.042 1135.9 402.042H1082.73C1063.12 402.042 1047.22 386.074 1047.22 366.376L1047.22 312.968C1047.22 293.27 1063.12 277.302 1082.73 277.302H1135.9C1155.52 277.302 1171.41 293.27 1171.41 312.968Z" fill="var(--c-key-visual)"/>
              <path d="M1273.9 402.042H1220.68C1201.02 402.042 1185.17 386.079 1185.17 366.421V299.468C1185.17 279.537 1169.14 263.437 1149.3 263.437H1082.64C1063.02 263.437 1047.13 247.428 1047.13 227.771L1047.13 174.317C1047.13 154.614 1063.07 138.651 1082.64 138.651H1135.86C1136.46 138.651 1137.05 138.666 1137.65 138.696H1149.57C1169.42 138.696 1185.45 122.596 1185.45 102.665L1185.45 35.711C1185.45 16.008 1201.38 0.0449917 1220.95 0.0449926L1274.17 0.0449949C1293.79 0.0449958 1309.68 16.0536 1309.68 35.711V89.1645C1309.68 108.868 1293.74 124.831 1274.17 124.831H1207.51C1187.67 124.831 1171.64 140.93 1171.64 160.862L1171.64 227.815C1171.64 229.322 1171.55 230.806 1171.37 232.262V241.271C1171.37 261.202 1187.4 277.302 1207.24 277.302H1273.9C1293.52 277.302 1309.36 293.265 1309.36 312.922L1309.36 366.421C1309.36 386.124 1293.47 402.042 1273.9 402.042Z" fill="var(--c-key-visual)"/>
              <path d="M1135.54 124.739H1082.36C1062.75 124.739 1046.85 108.771 1046.85 89.0727L1046.85 35.6648C1046.85 15.967 1062.75 -0.00125208 1082.36 -0.00125122L1135.54 -0.0012489C1155.15 -0.00124804 1171.04 15.967 1171.04 35.6649V89.0727C1171.04 108.771 1155.15 124.739 1135.54 124.739Z" fill="var(--c-key-visual)"/>
              <path d="M1082.36 541.029H1135.54C1155.15 541.029 1171.04 525.061 1171.04 505.363V451.955C1171.04 432.257 1155.15 416.289 1135.54 416.289H1082.36C1062.75 416.289 1046.85 432.257 1046.85 451.955L1046.85 505.363C1046.85 525.061 1062.75 541.029 1082.36 541.029Z" fill="var(--c-key-visual)"/>
              <path d="M308 6.10352e-05C290.879 6.10352e-05 277 13.8792 277 31.0001V233C277 250.121 290.879 264 308 264H388C403.464 264 416 276.536 416 292V510C416 527.121 429.879 541 447 541H693.915C694.03 541 694.123 541.093 694.123 541.208C694.123 541.323 694.216 541.417 694.331 541.417H1003.09C1018.55 541.417 1031.09 528.88 1031.09 513.417V28.0003C1031.09 12.5364 1018.55 0.000305176 1003.09 0.000305176H1000.12C1000.08 0.000142466 1000.04 6.10352e-05 1000 6.10352e-05H308Z" fill="url(#imagePattern)"/>
            </>
            <defs>
              <pattern
                id="imagePattern"
                patternUnits="userSpaceOnUse"
                width="100%"
                height="100%"
              >
                <image
                  href={imagePath}
                  x="20%"
                  y="0"
                  width="60%"
                  height="100%"
                  preserveAspectRatio="xMidYMid slice"
                ></image>
              </pattern>
            </defs>
          </svg>
          <svg
            viewBox="0 0 357 315"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="d-block d-lg-none"
          >
            {/* prettier-ignore */}
            <>
              <path d="M0.321917 132.326L0.321918 101.278C0.321919 89.8068 9.55338 80.5611 20.9213 80.5611L59.6406 80.5611C71.1667 80.5611 80.4773 71.2095 80.4773 59.6325V20.7423C80.4773 9.29776 89.7351 0.0256063 101.103 0.0256068L132.015 0.0256078C143.409 0.0256083 152.641 9.32426 152.641 20.7423V51.7909C152.641 63.2354 143.383 72.5076 132.015 72.5076H93.2958C81.7697 72.5076 72.4591 81.8592 72.4591 93.4362V132.326C72.4591 143.771 63.2277 153.017 51.8598 153.017H20.9213C9.52699 153.017 0.321917 143.744 0.321917 132.326Z" fill="var(--c-key-visual)"/>
              <path d="M101.124 153.097H132.01C143.401 153.097 152.635 143.822 152.635 132.38V101.358C152.635 89.9168 143.401 80.6416 132.01 80.6416H101.124C89.7327 80.6416 80.4983 89.9168 80.4983 101.358V132.38C80.4983 143.822 89.7327 153.097 101.124 153.097Z" fill="var(--c-key-visual)"/>
              <path d="M72.1373 212.81V181.788C72.1373 170.346 62.9029 161.071 51.5116 161.071H20.6258C9.23456 161.071 0.000123557 170.346 0.000123056 181.788L0.00012207 212.81C0.00012157 224.251 9.23456 233.527 20.6258 233.527H51.5116C62.9029 233.527 72.1373 224.251 72.1373 212.81Z" fill="var(--c-key-visual)"/>
              <path d="M51.8342 72.4556L20.9484 72.4556C9.55718 72.4556 0.322713 63.1804 0.322713 51.7389L0.322714 20.7168C0.322715 9.27531 9.55718 0.000121572 20.9484 0.00012207L51.8342 0.000123052C63.2255 0.00012355 72.46 9.27531 72.46 20.7168V51.7389C72.46 63.1804 63.2255 72.4556 51.8342 72.4556Z" fill="var(--c-key-visual)"/>
              <path d="M72.4332 262.296V293.318C72.4332 304.76 63.1987 314.035 51.8075 314.035H20.9217C9.53041 314.035 0.295941 304.76 0.295942 293.318L0.295943 262.296C0.295943 250.855 9.53041 241.579 20.9217 241.579H51.8075C63.1987 241.579 72.4332 250.855 72.4332 262.296Z" fill="var(--c-key-visual)"/>
              <path d="M357 28C357 12.536 344.464 4.44143e-06 329 4.44143e-06L189.273 0C173.809 0 161.273 12.536 161.273 28V133.273C161.273 148.737 148.737 161.273 133.273 161.273H109.37C93.9056 161.273 81.3696 173.809 81.3696 189.273L81.3696 286.482C81.3696 301.946 93.9056 314.482 109.37 314.482L329 314.483C344.464 314.483 357 301.947 357 286.483V28Z" fill="url(#imagePatternMobile"/>
            </>
            <defs>
              <pattern
                id="imagePatternMobile"
                patternUnits="userSpaceOnUse"
                width="100%"
                height="100%"
              >
                <image
                  href={imagePath}
                  x="20%"
                  y="0"
                  width="80%"
                  height="100%"
                  preserveAspectRatio="xMidYMid slice"
                ></image>
              </pattern>
            </defs>
          </svg>
        </>
      );
  }
};

KeyVisualMask.propTypes = {
  imagePath: PropTypes.string.isRequired,
};

export default KeyVisualMask;
