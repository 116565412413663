import { useLoaderData, useNavigation } from "react-router-dom";
import { useCanonicalOrRedirect } from "./use-canonical-or-redirect";
import { useI18n } from "../i18n/use-i18n";

const useNodeQuery = () => {
  /**
   * Get data from the react router loader.
   */
  const data = useLoaderData();
  const { state } = useNavigation();

  const route = useCanonicalOrRedirect(data);
  const entity = route?.entity;

  useI18n(route?.languageSwitchLinks);

  return { entity, state };
};

export default useNodeQuery;
