import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import Tags from "@general-components/tags/tags";
import Image from "@general-components/image/image";

const TeaserProjekt = ({ item, pagerFullPage = false }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <article
      style={{
        backgroundImage: `url("${item.fieldImage?.fieldMediaImage.style.url}")`,
      }}
      className="node node-teaser teaser-projekt card"
    >
      <EditButton
        adminApp={adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />
      <div className="card-body">
        {item.fieldTeaserbild && (
          <Image data={item.fieldTeaserbild.entity.fieldMediaImage} />
        )}
        <h3 className="headline-m">{item.title}</h3>
        {item.fieldTeasertext?.processed && (
          <div
            dangerouslySetInnerHTML={{
              __html: item.fieldTeasertext.processed,
            }}
          />
        )}
        <Link
          onClick={() => dispatch(pagerFullPageContextAction(pagerFullPage))}
          className="btn btn-primary"
          to={item.url?.path || item.path.alias}
        >
          Mehr dazu
        </Link>
      </div>
    </article>
  );
};

export const teaserProjektPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserProjekt.propTypes = {
  item: teaserProjektPropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default TeaserProjekt;
