import { NetworkError, NotFoundError } from "@system/exceptions";
import { eventPageQuery } from "./event/event.graphql";
import { landingPageQuery } from "./landingpage/landingpage.graphql";
import { newsPageQuery } from "./news/news.graphql";
import { personPageQuery } from "./person/person.graphql";
import { projektPageQuery } from "./projekt/projekt.graphql";
import { jobPageQuery } from "./job/job.graphql";

/**
 * Perform a node query for the given entity bundle.
 * The result is returned as a promise.
 *
 * @param {ApolloClient} client
 * @param {Request} request
 * @param {string} entityBundle
 * @returns {Promise} The query result
 */
export const nodeLoader = async (client, request, entityBundle) => {
  // get requested location
  const url = new URL(request.url);
  const path = url.pathname;

  // determine the query to use based on the entity bundle
  const query = (() => {
    switch (entityBundle) {
      case "event":
        return eventPageQuery;
      case "landingpage":
        return landingPageQuery;
      case "news":
        return newsPageQuery;
      case "person":
        return personPageQuery;
      case "projekt":
        return projektPageQuery;
      case "job":
        return jobPageQuery;
      default:
        return null;
    }
  })();

  // perform the query
  const { data } = await client.query({
    query,
    variables: {
      path,
    },
  });

  // if no data is returned, throw a network error
  if (!data) {
    throw new NetworkError();
  }

  // if no route is returned, throw a not found error
  if (!data?.route) {
    throw new NotFoundError();
  }

  // return the data
  return data;
};
