import React from "react";
import PropTypes from "prop-types";
import DateTime from "@general-components/date-time/date-time";
import { Link } from "react-router-dom";

const ParagraphTeaserHighlight = ({ content }) => {
  const ref = content.fieldReferencedContent?.entity;

  const validatedContent = {
    title: ref?.title,
  };

  switch (ref?.entityBundle) {
    case "news":
      validatedContent.small = (
        <DateTime
          value={ref?.publishedAt?.value}
          granularity="date"
          mode="long"
        />
      );
      validatedContent.text = ref?.fieldTeasertext?.processed;
      validatedContent.link = {
        label: "Beitrag lesen",
        url: ref?.entityUrl?.path,
      };
  }

  return (
    <section className="paragraph paragraph-teaser-highlight">
      {validatedContent.small && <small>{validatedContent.small}</small>}
      {validatedContent.title && <h2>{validatedContent.title}</h2>}
      {validatedContent.text && (
        <div dangerouslySetInnerHTML={{ __html: validatedContent.text }} />
      )}
      {validatedContent.link?.label && (
        <Link to={validatedContent.link.url} className="btn btn-light">
          {validatedContent.link.label}
        </Link>
      )}
    </section>
  );
};

ParagraphTeaserHighlight.propTypes = {
  content: PropTypes.shape({
    fieldReferencedContent: PropTypes.shape({
      entity: PropTypes.shape({
        title: PropTypes.string,
        entityBundle: PropTypes.string,
        publishedAt: PropTypes.shape({
          value: PropTypes.number,
        }),
        fieldTeasertext: PropTypes.shape({
          processed: PropTypes.string,
        }),
      }),
    }),
  }),
};

export default ParagraphTeaserHighlight;
