import React from "react";
import PropTypes from "prop-types";
import ErrorBoundary from "@js/error-boundary";
import Image from "@general-components/image/image";
import classNames from "classnames";

const BildSliderGGB = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-bilder-slider": true,
    [`format-${content.fieldFormat}`]: true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 col-md-12 offset-md-2 col-xl-8 offset-xl-4">
            <ErrorBoundary>
              <article className="media-image">
                <div className="content-wrap">
                  {content.fieldImageTitle && (
                    <h2 className="headline">{content.fieldImageTitle}</h2>
                  )}
                  <Image
                    data={content.fieldBilderWCaption[0].entity.fieldMediaImage}
                  />
                  {content.fieldBildunterschrift && (
                    <p className="description">
                      {content.fieldBildunterschrift}{" "}
                    </p>
                  )}
                </div>
              </article>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </section>
  );
};

BildSliderGGB.propTypes = {
  content: PropTypes.shape({
    fieldBildunterschrift: PropTypes.string,
    fieldBilderWCaption: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldMediaImage: PropTypes.shape({
            alt: PropTypes.string,
            title: PropTypes.string,
            style: PropTypes.shape({
              url: PropTypes.string.isRequired,
            }),
          }),
        }),
      })
    ),
    fieldFormat: PropTypes.oneOf(["center", "left", "right", "full"]),
    fieldImageTitle: PropTypes.string,
  }),
};

export default BildSliderGGB;
