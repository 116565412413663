import React from 'react'

const ThemeWrapper = ({ theme, children }) => {
  children = React.Children.map(children, (child) => {
    return React.cloneElement(child, { "data-theme": theme?.entity?.fieldMachineName || "default" });
  });

  return children;
}

export default ThemeWrapper