import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Link from "../../../link/link";
import VisibilitySensor from "react-visibility-sensor";

import Image from "../../../image/image";
import { self } from "../../../../config";
import ThemeWrapper from "@general-components/theme-wrapper/theme-wrapper";

import KeyVisual from "@assets/key-visual/key-visual-teaser-image.svg";

const ParagraphTeaserImage = ({ content }) => {
  return (
    <ThemeWrapper theme={content.fieldTheme}>
      <section className="paragraph paragraph-teaser-image">
        <div className="container">
          <div
            className={classNames({
              row: true,
              "flex-row-reverse": content.fieldImagePosition === "right",
            })}
          >
            <div className="col-16 col-lg-8 image-col">
              {content.fieldImage && (
                <>
                  {content.fieldTheme && (
                    <KeyVisual
                      className={content.fieldImagePosition + " key-visual"}
                    />
                  )}
                  <div className="rotate-img">
                    <Image
                      data={content.fieldImage.entity.fieldMediaImage}
                      nodeTitle={content.fieldHeading}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="col-16 col-lg-8 text-col">
              <h2>{content.fieldHeading}</h2>
              {content.fieldText && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: content.fieldText.value,
                  }}
                />
              )}
              {content.fieldCallToAction && (
                <Link
                  className="btn btn-primary with-arrow"
                  link={content.fieldCallToAction}
                >
                  <img
                    className="link-arrow"
                    alt="link arrow"
                    src={`${self}/arrow-down-right.svg`}
                  />
                  {content.fieldCallToAction.title}
                </Link>
              )}
            </div>
          </div>
        </div>
      </section>
    </ThemeWrapper>
  );
};

ParagraphTeaserImage.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    }),
    fieldCallToAction: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.string,
      }),
    }),
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
    }),
    fieldImagePosition: PropTypes.oneOf(["left", "right"]),
  }),
};

export default ParagraphTeaserImage;
