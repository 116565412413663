import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { graphql } from "@apollo/client/react/hoc";
import ReactPaginate from "react-paginate";
import { animateScroll as scroll } from "react-scroll";
import { connect } from "react-redux";
import { compose } from "recompose";
import moment from "moment";

import teaserNodeQuery from "../../../teaser-base/queries/teaser-node-query.graphql";
import teaserNodeQueryEvent from "../../../teaser-base/queries/teaser-node-query-event.graphql";
import teaserNodeQueryEventTagFiltered from "../../../teaser-base/queries/teaser-node-query-event-tag-filtered.graphql";
import teaserNodeQueryTagFiltered from "../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import TeaserPersonOverview from "../../../teaser-base/person/teaser-person-overview";
import TeaserNews from "../../../teaser-base/news/teaser-news";
import ComponentFilterCloud from "./components/component-filter-cloud";
import TeaserProjekt from "../../../teaser-base/project/teaser-projekt";
import { pagerFullPagesAction } from "../../../../app-actions";
import ComponentExtendedTeaserOverviewFilterText from "./components/component-extended-teaser-overview-filter-text";
import ComponentExtendedTeaserOverviewFilterDialogBaum from "./components/component-extended-teaser-overview-filter-dialog-baum";
import ComponentExtendedTeaserOverviewSearch from "./components/component-extended-teaser-overview-search";
import ErrorBoundary from "../../../../error-boundary";
import TeaserEvent from "../../../teaser-base/event/teaser-event";
import Image from "../../../image/image";
import ParagraphProjektTeaserGgb from "../../../../microsites/ggb/components/projekt teaser/paragraph-projekt-teaser";
import TeaserBase from "@teasers/teaser-base";

const mapStateToProps = (reduxStore) => ({
  microsite: reduxStore.appStore.microsite,
});

class ParagraphExtendedTeaserOverview extends Component {
  static defaultProps = { skipQuery: false };

  itemSection = React.createRef();

  filterItems = () => {
    let filteredItems = [];

    const activeFilter = this.state ? this.state.activeFilter : null,
      activeSearch = this.state ? this.state.activeSearch : null;

    if (
      (activeFilter && activeFilter.entityId) ||
      (activeSearch && activeSearch !== "")
    ) {
      if (activeFilter && activeFilter.entityId) {
        filteredItems = this.props.nodes.nodeQuery.entities.filter((item) =>
          item.fieldSchlagwort.some((tag) =>
            tag.entity ? tag.entity.entityId === activeFilter.entityId : false
          )
        );
      } else {
        filteredItems = this.props.nodes.nodeQuery.entities;
      }

      if (activeSearch !== "") {
        filteredItems = filteredItems.filter((item) => {
          const parsedItem = JSON.stringify(item).toLowerCase();

          return parsedItem.includes(activeSearch);
        });
      }
    } else {
      filteredItems = this.props.nodes.nodeQuery.entities;
    }

    return filteredItems;
  };

  getItemsToShow = (start, end) => {
    if (
      !this.props.nodes.loading &&
      this.props.nodes.nodeQuery.entities.length >= 1
    ) {
      if (!end) {
        end = this.props.nodes.nodeQuery.entities.length;
      }

      const items = this.filterItems();

      if (this.props.content.fieldPagerVerwenden) {
        return items.slice(start, end);
      }

      return items;
    }

    return [];
  };

  state = {
    itemsMatching: this.getItemsToShow(0),
    itemsToShow: this.getItemsToShow(
      0,
      this.props.content.fieldPagerVerwenden
        ? this.props.content.fieldElementeProSeite
        : null
    ),
    activeFilter: {},
    activeSearch: "",
  };

  pushPagerFullPageConfig = () => {
    if (
      this.props.content.fieldPagerAufVollseiten &&
      this.props.nodes.nodeQuery
    ) {
      const pagerFullPagesConfig = {
        id: this.props.content.entityId,
        items: this.props.nodes.nodeQuery.entities,
        overviewLink: this.props.content.fieldLinkZurUebersicht,
      };

      this.props.dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  componentDidMount() {
    // Pager on full screen pages.
    this.pushPagerFullPageConfig();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.nodes.nodeQuery) !==
      JSON.stringify(this.props.nodes.nodeQuery)
    ) {
      this.setState({
        itemsMatching: this.getItemsToShow(0),
        itemsToShow: this.getItemsToShow(
          0,
          this.props.content.fieldPagerVerwenden
            ? this.props.content.fieldElementeProSeite
            : null
        ),
      });
      this.pushPagerFullPageConfig();
    }
  }

  changeActiveFilter = (activeFilter) => {
    if (
      this.state.activeFilter &&
      (this.state.activeFilter.entityId === activeFilter.entityId ||
        activeFilter.entityId === "none")
    ) {
      this.setState({ activeFilter: null }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.content.fieldPagerVerwenden
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    } else {
      this.setState({ activeFilter }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.content.fieldPagerVerwenden
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    }
  };

  changeActiveSearch = (searchValue) => {
    if (typeof searchValue === "undefined") {
      this.setState({ activeSearch: "" }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.content.fieldPagerVerwenden
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    } else {
      this.setState({ activeSearch: searchValue.toLowerCase() }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.content.fieldPagerVerwenden
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    }
  };

  handlePageClick = (data) => {
    this.setState(
      {
        itemsToShow: this.getItemsToShow(
          data.selected * this.props.content.fieldElementeProSeite,
          data.selected * this.props.content.fieldElementeProSeite +
            this.props.content.fieldElementeProSeite
        ),
      },
      () => scroll.scrollTo(this.itemSection.current.offsetTop)
    );
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-extended-teaser-overview": true,
      [`type-${this.props.content.fieldTypExtended}`]: true,
      "parallax-effect": this.props.content.fieldParallaxEffect
    });
    const colClassNames = classNames({
      "col-16": true,
      "col-lg-12 offset-lg-2":
        this.props.microsite !== "ggb" && !this.props.content.fieldParallaxEffect,
    });
    return (
      <section className={sectionClassNames} ref={this.itemSection}>
        {this.props.content.fieldTitle && (
          <div className="container headline-wrapper">
            <div className="row text-center">
              <h2 className="headline-xl">{this.props.content.fieldTitle}</h2>
            </div>
          </div>
        )}
        <div className="container">
          <div className="row">
            <div className={colClassNames}>
              <div className="row">
                {this.state.itemsToShow.length >= 1 &&
                  this.state.itemsToShow.map((item, index) => (
                    <React.Fragment key={index}>
                      <div
                        className={classNames({
                          "col-16 col-md-8":
                            this.state.itemsToShow?.length !== 3,
                          "col-16 col-lg-8 col-xl-third":
                            this.props.content.fieldParallaxEffect,
                          "teaser-wrapper": true,
                        })}
                      >
                        <TeaserBase item={item} viewMode={item.fieldType} />
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </div>

          {!this.props.nodes.loading &&
            this.props.nodes.nodeQuery.entities.length >
              this.props.content.fieldElementeProSeite &&
            this.props.content.fieldPagerVerwenden && (
              <div className="row">
                <div className="col-16">
                  <div className="pagination">
                    <ErrorBoundary>
                      <ReactPaginate
                        onPageChange={this.handlePageClick}
                        pageCount={Math.ceil(
                          this.state.itemsMatching.length /
                            this.props.content.fieldElementeProSeite
                        )}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        previousLabel={"Vorherige Seite"}
                        nextLabel={"Nächste Seite"}
                        previousClassName="btn btn-primary previous"
                        nextClassName="btn btn-primary next"
                      />
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
            )}
        </div>
      </section>
    );
  }
}

ParagraphExtendedTeaserOverview.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string,
    fieldDarstellung: PropTypes.oneOf(["small_big", "small_highlighted"]),
    fieldElementeProSeite: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldLinkZurUebersicht: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.string,
      }),
    }),
    fieldTypExtended: PropTypes.oneOf([
      "news",
      "person",
      "projekt",
      "veranstaltung",
    ]),
    fieldPagerVerwenden: PropTypes.bool,
    fieldFilterwolke: PropTypes.bool,
    fieldFilterImText: PropTypes.bool,
    fieldFilterDialogBaum: PropTypes.bool,
    fieldSucheAktivieren: PropTypes.bool,
    fieldFilterImTextReference: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityBundle: PropTypes.oneOf([
            "filtertext_text",
            "filtertext_filter",
          ]),
          fieldFilterTextText: PropTypes.string,
          fieldFilter: PropTypes.shape({
            entity: PropTypes.shape({
              entityLabel: PropTypes.string,
              entityId: PropTypes.string,
            }),
          }),
        }),
      })
    ),
    fieldFilterDialogBaumReferen: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityId: PropTypes.string,
          entityBundle: PropTypes.oneOf(["filtertext_text", "filteroptionen"]),
          fieldFilterTextText: PropTypes.string,
          fieldFilterMultiple: PropTypes.arrayOf(
            PropTypes.shape({
              targetId: PropTypes.string,
              entity: PropTypes.shape({
                entityLabel: PropTypes.string,
              }),
            })
          ),
        }),
      })
    ),
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.string,
      })
    ),
  }),
  dispatch: PropTypes.func.isRequired,
  nodes: PropTypes.object.isRequired,
  skipQuery: PropTypes.bool,
};

/* TODO: Create own components for extended teaser paragraphs and add own
 *   queries for each component to prevent this chaos.
 * TODO: Refactor all teaserNodeQueries - work with fragments and just make
 *  the conditions and sorting unique for the queries. */
export default compose(
  // Everything except events.
  graphql(teaserNodeQuery, {
    name: "nodes",
    skip: (props) =>
      !props.content.fieldTypExtended ||
      props.content.fieldManuelleSortierung ||
      props.content.fieldTypExtended === "veranstaltung" ||
      props.content.fieldSchlagwort.length > 0 ||
      props.skipQuery,
    options: (props) => ({
      variables: {
        limit: 10000,
        type: [props.content.fieldTypExtended],
      },
    }),
  }),
  graphql(teaserNodeQueryTagFiltered, {
    name: "nodes",
    skip: (props) =>
      !props.content.fieldTypExtended ||
      props.content.fieldManuelleSortierung ||
      props.content.fieldTypExtended === "veranstaltung" ||
      props.content.fieldSchlagwort.length === 0 ||
      props.skipQuery,
    options: (props) => ({
      variables: {
        limit: 10000,
        type: [props.content.fieldTypExtended],
        tags: props.content.fieldSchlagwort.map((item) =>
          item.targetId.toString()
        ),
      },
    }),
  }),
  graphql(teaserNodeQueryEvent, {
    name: "nodes",
    skip: (props) =>
      props.content.fieldTypExtended !== "veranstaltung" ||
      props.content.fieldManuelleSortierung ||
      props.content.fieldSchlagwort.length > 0 ||
      props.skipQuery,
    options: (props) => ({
      variables: {
        date: moment().format("YYYY-MM-DD"),
        limit: 10000,
        type: [props.content.fieldTypExtended],
      },
    }),
  }),
  graphql(teaserNodeQueryEventTagFiltered, {
    name: "nodes",
    skip: (props) =>
      props.content.fieldTypExtended !== "veranstaltung" ||
      props.content.fieldManuelleSortierung ||
      props.content.fieldSchlagwort.length === 0 ||
      props.skipQuery,
    options: (props) => ({
      variables: {
        date: moment().format("YYYY-MM-DD"),
        limit: 10000,
        type: [props.content.fieldTypExtended],
        tags: props.content.fieldSchlagwort.map((item) =>
          item.targetId.toString()
        ),
      },
    }),
  })
)(connect(mapStateToProps)(ParagraphExtendedTeaserOverview));
