import React from "react";

// Route Components
import AppLayout from "./app";
import Event from "@nodes/event/event";
import Landingpage from "@nodes/landingpage/landingpage";
import News from "@nodes/news/news";
import Person from "@nodes/person/person";
import Projekt from "@nodes/projekt/projekt";


// Route Errors
import RouteError from "@system/route-error";

// Loaders
import { nodeLoader } from "@nodes/node-loader";
import { NotFoundError } from "@system/exceptions";
import Job from "@nodes/job/job";

/**
 * Define the data routes
 * @see https://reactrouter.com/en/main/guides/ssr
 *
 * @param {ApolloClient} client
 * @returns
 */
const routes = (client) => [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        errorElement: <RouteError />,
        children: [
          {
            index: true,
            element: <Landingpage />,
            loader: async ({ request }) => {
              return await nodeLoader(client, request, "landingpage");
            },
          },
          {
            path: `:language?/:alias?`,
            element: <Landingpage />,
            loader: async ({ request }) => {
              return await nodeLoader(client, request, "landingpage");
            },
          },
          {
            path: `:language?/news/:alias`,
            element: <News />,
            loader: async ({ request }) => {
              return await nodeLoader(client, request, "news");
            },
          },
          {
            path: `:language?/veranstaltung/:alias`,
            element: <Event />,
            loader: async ({ request }) => {
              return await nodeLoader(client, request, "event");
            },
          },
          {
            path: `:language?/person/:alias`,
            element: <Person />,
            loader: async ({ request }) => {
              return await nodeLoader(client, request, "person");
            },
          },
          {
            path: `:language?/produkt/:alias`,
            element: <Projekt />,
            loader: async ({ request }) => {
              return await nodeLoader(client, request, "projekt");
            },
          },
          
          {
            path: `:language?/job/:alias`,
            element: <Job />,
            loader: async ({ request }) => {
              return await nodeLoader(client, request, "job");
            },
          },
          {
            path: "*",
            loader: () => {
              throw new NotFoundError();
            },
          },
        ],
      },
    ],
  },
];

export default routes;
