import React from "react";
import { FormattedMessage } from "react-intl";

const ButtonApply = () => {
  const applyHandler = () => {
    alert("not implemented yet");
  }

  return (
    <section className="paragraph button-apply">
      <div className="container d-flex justify-content-center">
        <button className="btn btn-primary btn-lg" onClick={applyHandler}>
          <FormattedMessage id="job.apply" />
        </button>
      </div>
    </section>
  );
};

export default ButtonApply;
