import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import Tags from "@general-components/tags/tags";
import Image from "@general-components/image/image";
import Hyphenate from "@general-components/hyphenate/hyphenate";

const TeaserService = ({ item, pagerFullPage = false }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const dispatch = useDispatch();
  const location = useLocation();

  const teaserTitle = item.fieldTeaserTitle
    ? <span dangerouslySetInnerHTML={{ __html: item.fieldTeaserTitle?.replaceAll("_", "<br/>") }}/>
    : <Hyphenate hyphenChar="&#8203;">{item.title}</Hyphenate>

  return (
    <Link
      onClick={() => dispatch(pagerFullPageContextAction(pagerFullPage))}
      to={item.url?.path || item.path.alias}
      className="node node-teaser teaser-service card"
    >
      <EditButton
        adminApp={adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />
      <div className="card-body">
        <h3
          className="headline-dynamic"
          data-size={item.title.length > 8 ? "small" : "large"}
        >
          {teaserTitle}
        </h3>
        <div className="text-l">
          <span>{item.fieldZwischenueberschrift}</span>
        </div>
      </div>
    </Link>
  );
};

export const teaserServicePropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserService.propTypes = {
  item: teaserServicePropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default TeaserService;
