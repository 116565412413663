import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import teaserNodeQueryFilterTag from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../../loading-indicator";
import TeaserNews, {
  teaserNewsPropTypes,
} from "../../../../teaser-base/news/teaser-news";
import TeaserPerson, {
  teaserPersonPropTypes,
} from "../../../../teaser-base/person/teaser-person";
import TeaserBase from "../../../../teaser-base/teaser-base";
import { teaserEventPropTypes } from "../../../../teaser-base/event/teaser-event";
import { teaserGeneralPropTypes } from "../../../../teaser-base/general/teaser-general";
import { teaserProjektPropTypes } from "../../../../teaser-base/project/teaser-projekt";
import { useQuery } from "@apollo/client";
import ReactPaginate from "react-paginate";
import IconChevronLeft from "@assets/icons/icon-chevron-left.svg";
import IconChevronRight from "@assets/icons/icon-chevron-right.svg";
import { motion, AnimatePresence } from "motion/react";

const ComponentTeaserlistWrap = (props) => {
  const currentLanguage = useSelector((state) => state.i18n.currentLanguage);
  const [offset, setOffset] = useState(0);

  console.log(props)
  const { data, loading, error } = useQuery(teaserNodeQueryFilterTag, {
    name: "nodes",
    skip: props.nodesConfig === "Manuell",
    variables: {
      limit: props.count ? props.count : 100,
      offset,
      type: props.type === "all" ? ["news", "person"] : [props.type],
      tag: props.tags.map((item) => item.targetId),
      filterTagEnabled: props.tags.length > 0,
      language: currentLanguage.toUpperCase(),
    },
  });

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  let nodes;
  if (props.nodesConfig === "Manuell") {
    nodes = props.manualNodes || [];
  }
  if (props.nodesConfig === "Automatisch (chronologisch)") {
    nodes = data?.nodeQuery?.entities || [];
  }

  return (
    <>
      <AnimatePresence mode="wait">
        {loading && <LoadingIndicator />}
        {!!nodes?.length &&
          nodes.map((item) => (
            <motion.div
              key={item.entityId}
              className="col-16 col-sm-8 col-lg-third"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {(() => {
                switch (item.entityBundle) {
                  case "news":
                    return (
                      <TeaserNews
                        item={item}
                        pagerFullPage={props.pagerFullPage}
                      />
                    );
                  case "person":
                    return (
                      <TeaserPerson
                        count={props.count}
                        item={item}
                        pagerFullPage={props.pagerFullPage}
                      />
                    );
                  case "veranstaltung":
                    return (
                      <TeaserBase
                        item={item}
                        single={false}
                        viewMode={"teaserlist"}
                        pagerFullPage={props.pagerFullPage}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </motion.div>
          ))}
      </AnimatePresence>
      {!!nodes?.length && !!props.count && (
        <div className="pagination">
          <ReactPaginate
            pageCount={Math.ceil(data.nodeQuery.count / props.count)}
            nextLabel={<IconChevronRight />}
            previousLabel={<IconChevronLeft />}
            pageRangeDisplayed={3}
            onPageChange={({ selected }) => {
              console.log(selected);
              setOffset(selected * props.count);
            }}
            initialPage={offset / props.count}
          />
        </div>
      )}
    </>
  );
};

ComponentTeaserlistWrap.propTypes = {
  count: PropTypes.number.isRequired,
  manualNodes: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.oneOfType([
        teaserNewsPropTypes,
        teaserEventPropTypes,
        teaserPersonPropTypes,
        teaserGeneralPropTypes,
        teaserProjektPropTypes,
      ]),
    })
  ),
  id: PropTypes.string.isRequired,
  nodesConfig: PropTypes.oneOf(["Automatisch (chronologisch)", "Manuell"]),
  type: PropTypes.oneOf(["news", "person", "veranstaltung", "all"]),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      targetId: PropTypes.string,
    })
  ),
  nodes: PropTypes.object,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  pagerFullPageOverviewLink: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

export default ComponentTeaserlistWrap;
