import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ThemeWrapper from "@general-components/theme-wrapper/theme-wrapper";
import DateTime from "@general-components/date-time/date-time";
import ErrorBoundary from "@js/error-boundary";

const NewsIntro = ({ theme, content }) => {
  useEffect(() => {
    document.body.dataset.theme = theme;

    return () => {
      delete document.body.dataset.theme;
    };
  }, []);

  return (
    <ThemeWrapper theme={{ entity: { fieldMachineName: "theme-2" } }}>
      <section className="paragraph paragraph-intro">
        <div className="container">
          <ErrorBoundary>
            <DateTime
              value={content.publishedAt?.value}
              mode="long"
              granularity="date"
              className="small"
            />
          </ErrorBoundary>
          <h1>{content.title}</h1>
          {content.fieldTeasertext && (
            <div
              className="text text-l"
              dangerouslySetInnerHTML={{
                __html: content.fieldTeasertext?.processed,
              }}
            />
          )}
        </div>
      </section>
    </ThemeWrapper>
  );
};

NewsIntro.propTypes = {};

export default NewsIntro;
