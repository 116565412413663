import React from "react";
import PropTypes from "prop-types";
import { Play, Pause } from "@carbon/icons-react";
import classNames from "classnames";
import {self} from "../../../../config";

class ComponentVideo extends React.Component {
  state = {
    videoPlaying: false,
  };

  videoSection = React.createRef();

  playPauseVideo = () => {
    const video = this.videoSection.current.querySelectorAll("video")[0];

    if (video.paused) {
      // Play the video
      video.play();

      // Update the button text to 'Pause'
      this.setState({ videoPlaying: true });
    } else {
      // Pause the video
      video.pause();

      // Update the button text to 'Play'
      this.setState({ videoPlaying: false });
    }
  };

  handleKeyDownPlayPauseButton = (event) => {
    console.log(event.keyCode);

    switch (event.keyCode) {
      case 32: // Space
        this.playPauseVideo();
        break;
    }
  };

  // Keyboard Controlling of the Video Section
  handleKeyDownVideo = (event) => {
    const video = event.target;

    switch (event.keyCode) {
      case 32: // Space
        if (video.paused) {
          video.play();
          // Update the button text to 'Pause'
          this.setState({ videoPlaying: true });
        } else {
          video.pause();
          // Update the button text to 'Play'
          this.setState({ videoPlaying: false });
        }
        break;
      case 37: // Left arrow
        video.currentTime -= 10;
        break;
      case 39: // Right arrow
        video.currentTime += 10;
        break;
      case 38: // Up arrow
        video.volume += 0.1;
        break;
      case 40: // Down arrow
        video.volume -= 0.1;
        break;
      case 77: // M
        if (video.muted) {
          video.muted = false;
        } else {
          video.muted = true;
        }
        break;
    }
  };

  render() {
    return (
      <section
        className={classNames({
          [this.props.sectionClassNames]: true,
          "video-playing": this.state.videoPlaying,
        })}
        ref={this.videoSection}
      >
        {this.props.content.fieldGroesse === "0" && (
          <div className="full-width local-video">
            {Boolean(this.props.content.fieldVideoFileMedia) && (
              <>
                <video
                  src={
                    this.props.content.fieldVideoFileMedia.entity
                      .fieldMediaVideoFile.entity.url
                  }
                  tabIndex={0}
                  controls={false}
                  aria-controls="playpause"
                  onKeyDown={this.handleKeyDownVideo}
                  onEnded={() => this.setState({ videoPlaying: false })}
                />
                <div
                  tabIndex={0}
                  className="playpause"
                  role="button"
                  aria-label="Play or pause the video"
                  onClick={() => this.playPauseVideo()}
                  onKeyDown={this.handleKeyDownPlayPauseButton}
                >
                  {this.state.videoPlaying ? <Pause size={24} /> : <Play size={24} />}
                </div>
              </>
            )}
          </div>
        )}
        {this.props.content.fieldGroesse === "1" && (
          <div className="container">
            <div className="row">
              <div className="col-16 col-lg-12 offset-lg-2 local-video">
                {Boolean(this.props.content.fieldVideoFileMedia) && (
                  <>
                    <video
                      src={
                        this.props.content.fieldVideoFileMedia.entity
                          .fieldMediaVideoFile.entity.url
                      }
                      tabIndex={0}
                      controls={false}
                      aria-controls="playpause"
                      onKeyDown={this.handleKeyDownVideo}
                      onEnded={() => this.setState({ videoPlaying: false })}
                    />
                    <div
                      tabIndex={0}
                      className="playpause"
                      role="button"
                      aria-label="Play or pause the video"
                      onClick={() => this.playPauseVideo()}
                      onKeyDown={this.handleKeyDownPlayPauseButton}
                    >
                      {this.state.videoPlaying ? <img src={`${self}/video-pause.svg`}/> : <img src={`${self}/video-play.svg`}/>}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

ComponentVideo.propTypes = {
  content: PropTypes.shape({
    /**
     * 0 = 100%, 1 = 50%
     */
    fieldGroesse: PropTypes.oneOf(["0", "1"]),
    fieldVideoFileMedia: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaVideoFile: PropTypes.shape({
          entity: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    }),
  }),
  sectionClassNames: PropTypes.string,
};

export default ComponentVideo;
