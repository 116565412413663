import React from "react";
import PropTypes from "prop-types";

import ErrorBoundary from "../../error-boundary";
import ParagraphSwitch from "./paragraph-switch";

const ContentBase = (props) => {
  return (
    <section className={`content-wrap ${props.className}`}>
      {props.content.map((item, index) => (
        // eslint-disable-next-line complexity
        <ErrorBoundary key={index}>
          <ParagraphSwitch
            paragraphItem={item.entity}
            nodeContent={props.nodeContent}
          />
        </ErrorBoundary>
      ))}
    </section>
  );
};

ContentBase.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        fieldReusableParagraph: PropTypes.object,
        entityBundle: PropTypes.string.isRequired,
        fieldManuelleSortierung: PropTypes.bool,
      }),
    })
  ),
  className: PropTypes.string,
  nodeContent: PropTypes.object,
};

export default ContentBase;
