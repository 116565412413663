import React from "react";
import PropTypes from "prop-types";

const ComponentVideo = ({ content }) => {
  if (!content?.fieldVideo) return null;

  return (
    <section className="paragraph intro-video">
      <div className="container">
          <video
            src={content.fieldVideo.entity.fieldMediaVideoFile.entity.url}
            controls={false}
            muted={true}
            loop={true}
            autoPlay={true}
            playsInline={true}
          />
      </div>
    </section>
  );
};

ComponentVideo.propTypes = {
  content: PropTypes.shape({
    fieldVideo: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaVideoFile: PropTypes.shape({
          entity: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    }),
  }),
  sectionClassNames: PropTypes.string,
};

export default ComponentVideo;
