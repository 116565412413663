import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import classNames from "classnames";

import EditButton from "../../../backend/edit-button";
import Image from "../../image/image";
import { teaserEventPropTypes } from "./teaser-event";
import getEncodedIcalUrl from "../../../lib/get-encoded-ical-url";

const TeaserEventEinzelDoppel = ({ item, single }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const location = useLocation();
  const intl = useIntl();

  return (
    <article
      className={`node node-${item.id} node-teaser teaser-event teaser-doppel-einzel`}
    >
      <EditButton
        adminApp={adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />
      <div
        className={classNames({
          row: true,
          "flex-row-reverse": single,
        })}
      >
        <div
          className={classNames({
            "col-16": true,
            "col-md-8": single,
          })}
        >
          {item.fieldImage !== null && (
            <Image
              data={item.fieldImage.fieldMediaImage}
              nodeTitle={item.title}
            />
          )}
        </div>

        <div
          className={classNames({
            "col-16": true,
            "col-md-8": single,
          })}
        >
          <div className="row">
            <div className="col-16 col-md-6">
              <div className="meta-date top-line">
                <span>
                  {moment.utc(item.fieldDate.value).local().format("ddd")}
                </span>
                {moment.utc(item.fieldDate.value).local().format("HH:mm") !==
                  "00:00" && (
                  <span>
                    {moment.utc(item.fieldDate.value).local().format("HH:mm")}{" "}
                    Uhr
                  </span>
                )}
              </div>
              <div className="date">
                <span>
                  {moment
                    .utc(item.fieldDate.value)
                    .local()
                    .format("DD.MM.YYYY")}
                </span>
              </div>
              <div className="location">{item.fieldLocation}</div>
              {item.fieldDate.endValue && !item.fieldShowDuration && (
                <div className="duration">
                  Dauer{" "}
                  {Math.round(
                    moment
                      .duration(
                        moment
                          .utc(item.fieldDate.endValue)
                          .local()
                          .diff(moment.utc(item.fieldDate.value).local())
                      )
                      .asHours()
                  )}{" "}
                  Std.
                </div>
              )}
              <div className="buttons">
                <a
                  href={getEncodedIcalUrl(
                    item.fieldDate,
                    item.fieldLocation,
                    item.title,
                    item.fieldText
                  )}
                  className="btn btn-primary"
                >
                  <FormattedMessage id="event.remember" />
                </a>
                {item.fieldTicketlink && (
                  <a
                    href={item.fieldTicketlink.uri.path}
                    className="btn btn-primary"
                  >
                    <FormattedMessage id="event.ticket" />
                  </a>
                )}
              </div>
            </div>
            <div className="col-16 col-md-10">
              {item.fieldTags.length > 0 && (
                <span className="top-line">{item.fieldTags[0].name}</span>
              )}
              <h3>{item.title}</h3>
              <div
                className="text teaser-text d-none d-lg-block"
                dangerouslySetInnerHTML={{
                  __html: `${item.fieldText} <a href="${
                    item.url ? item.url.path : item.path.alias
                  }">${intl.formatMessage({
                    id: "read_more",
                  })}</a>`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

TeaserEventEinzelDoppel.propTypes = {
  item: teaserEventPropTypes,
  single: PropTypes.bool.isRequired,
};

export default TeaserEventEinzelDoppel;
