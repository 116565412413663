import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import classNames from "classnames";

import Image from "../../image/image";
import EditButton from "../../../backend/edit-button";
import { teaserPersonPropTypes } from "./teaser-person";
import ContactInformation from "./components/contact-information";

const TeaserPersonContact = ({ item, showImage = true }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const location = useLocation();

  return (
    <article className="node node-teaser teaser-person teaser-contact">
      <EditButton
        adminApp={adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />
      <div className="d-flex align-items-center">
        {item.fieldBildWCaption?.[0]?.entity?.fieldMediaImage && (
          <div className="image">
            <Image
              data={item.fieldBildWCaption[0].entity.fieldMediaImage}
              nodeTitle={item.title}
            />
          </div>
        )}
        <div className="text">
          <h3>{item.title}</h3>
          {/* <div className="role">{item.fieldRole}</div> */}
          <ContactInformation content={item} />
          {item.fieldText && (
            <div
              className="info"
              dangerouslySetInnerHTML={{ __html: item.fieldText }}
            />
          )}
        </div>
      </div>
    </article>
  );
};

TeaserPersonContact.propTypes = {
  item: teaserPersonPropTypes,
  showImage: PropTypes.bool.isRequired,
};

TeaserPersonContact.defaultProps = { showImage: true };

export default TeaserPersonContact;
