import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import LoadingIndicator from "../../../../loading-indicator";
import { teaserNewsPropTypes } from "@teasers/news/teaser-news";
import { teaserPersonPropTypes } from "@teasers/person/teaser-person";
import TeaserBase from "@teasers/teaser-base";
import SliderProvider from "@general-components/slider/context";
import {
  Slide,
  Slider,
  SliderControls,
  SliderDots,
} from "@general-components/slider/slider";
import useTeaserQuery from "./use-teaser-query";
import { teaserEventPropTypes } from "@teasers/event/teaser-event";
import { teaserGeneralPropTypes } from "@teasers/general/teaser-general";
import { teaserProjektPropTypes } from "@teasers/project/teaser-projekt";

const sliderSettings = {
  slidesToScroll: 1,
  align: "start",
  loop: false,
  gap: "var(--space-sm)",
};

const ComponentTeaserlistCarousel = (props) => {
  const dispatch = useDispatch();
  const { nodes, loading } = useTeaserQuery(props.manualNodes, {
    ...props,
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!nodes?.length) {
    return <h2>Teaserlist</h2>;
  }

  return (
    <div className="container">
      <SliderProvider options={sliderSettings}>
        <Slider>
          {nodes.map((item, index) => (
            <Slide key={index}>
              <TeaserBase item={item} />
            </Slide>
          ))}
        </Slider>
        <SliderDots />
      </SliderProvider>
    </div>
  );
};

ComponentTeaserlistCarousel.propTypes = {
  count: PropTypes.number.isRequired,
  manualNodes: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.oneOfType([
        teaserNewsPropTypes,
        teaserEventPropTypes,
        teaserPersonPropTypes,
        teaserGeneralPropTypes,
        teaserProjektPropTypes,
      ]),
    })
  ),
  id: PropTypes.string.isRequired,
  nodesConfig: PropTypes.oneOf(["Automatisch (chronologisch)", "Manuell"]),
  type: PropTypes.oneOf(["news", "person", "veranstaltung", "all"]),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      targetId: PropTypes.string,
    })
  ),
  pagerFullPageOverviewLink: PropTypes.object,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default ComponentTeaserlistCarousel;
