import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ChevronDown, ChevronUp } from "@carbon/icons-react";

class ParagraphStrukturierteListe extends Component {
  state = { open: false };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-strukturierte-liste": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-16 col-md-15 offset-md-1">
              <div className="row">
                <div className="col-16 col-md-13">
                  <h3>{this.props.content.fieldHeading}</h3>
                </div>
                {this.props.content.fieldAusklappenEinklappen && (
                  <div className="col-16 col-md-3 d-md-flex justify-content-end align-items-baseline">
                    <div
                      className="btn btn-secondary btn-icon"
                      onClick={() => this.setState({ open: !this.state.open })}
                    >
                      {this.state.open ? (
                        <>
                          Einklappen <ChevronUp size={24} />
                        </>
                      ) : (
                        <>
                          Ausklappen <ChevronDown size={24} />
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div
                className={classNames({
                  "row content": true,
                  "d-none": !this.state.open,
                  "d-flex":
                    this.state.open ||
                    !this.props.content.fieldAusklappenEinklappen,
                })}
              >
                {this.props.content.fieldListen.map((item, index) => (
                  <div
                    key={index}
                    className={classNames({
                      "list-item": true,
                      "col-16 col-lg-14":
                        this.props.content.fieldListen.length === 1,
                      "col-16 col-lg-8":
                        this.props.content.fieldListen.length > 1,
                    })}
                  >
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{ __html: item.processed }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {this.props.content.fieldHint && (
          <div
            className={classNames({
              "hint text": true,
              "d-none": !this.state.open,
              "d-flex":
                this.state.open ||
                !this.props.content.fieldAusklappenEinklappen,
            })}
          >
            <div className="container">
              <div className="row">
                <div className="col-16">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.content.fieldHint.processed,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

ParagraphStrukturierteListe.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldAusklappenEinklappen: PropTypes.bool,
    fieldHint: PropTypes.shape({
      processed: PropTypes.string,
    }),
    fieldListen: PropTypes.arrayOf(
      PropTypes.shape({
        processed: PropTypes.string,
      })
    ),
  }),
};

export default ParagraphStrukturierteListe;
