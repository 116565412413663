import { useIntl } from "react-intl";

const quotationMarks = {
  "en-US": { open: '"', close: '"' }, // USA: gerade Anführungszeichen
  "de-DE": { open: "„", close: "“" }, // Deutschland: unten/oben
  "fr-FR": { open: "« ", close: " »" }, // Frankreich: Guillemets mit Leerzeichen
  "en-GB": { open: "‘", close: "’" }, // Großbritannien: einfache Anführungszeichen
  "es-ES": { open: "«", close: "»" }, // Spanien: Guillemets ohne Leerzeichen
  // Weitere Länder hinzufügen
};

const useQuotationMarks = (str) => {
  const { locale } = useIntl();

  let quotOpen = quotationMarks["de-DE"].open;
  let quotClose = quotationMarks["de-DE"].close;

  if (quotationMarks[locale]) {
    quotOpen = quotationMarks[locale].open;
    quotClose = quotationMarks[locale].close;
  }

  return `${quotOpen}${str}${quotClose}`;
};

export default useQuotationMarks;
