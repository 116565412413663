import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserListWrap from "./components/component-teaserlist-wrap";
import TeaserEventList from "./events/component-teaser-event-list";
import ComponentTeaserlistCarousel from "./components/component-teaserlist-carousel";
import Link from "../../../link/link";
import ErrorBoundary from "../../../../error-boundary";
import { teaserNewsPropTypes } from "../../../teaser-base/news/teaser-news";
import { teaserEventPropTypes } from "../../../teaser-base/event/teaser-event";
import { teaserPersonPropTypes } from "../../../teaser-base/person/teaser-person";
import { teaserGeneralPropTypes } from "../../../teaser-base/general/teaser-general";
import { teaserProjektPropTypes } from "../../../teaser-base/project/teaser-projekt";
import ThemeWrapper from "@general-components/theme-wrapper/theme-wrapper";

/**
 * This is the component for the "Eierlegende Wollmilchsau" paragraph.
 * Try not not to use this! This is just for reference.
 *
 * Usage: Copy to
 * src/js/general-components/content-base/paragraphs/teaserliste/<content_type>/paragraphs-teaser-list<content_type>.jsx
 * and change/remove/add as needed.
 */
class ParagraphTeaserList extends Component {
  render() {
    const sectionClassNames = classNames({
        "paragraph paragraph-teaser-list": true,
        [`paragraph-teaser-list-${this.props.content.fieldAnzahlDerAnzuzeigendenI}`]: true,
        "paragraph paragraph-teaser-list-slick":
          this.props.content.fieldKarussel,
        [`paragraph-teaser-list-${this.props.content.fieldTyp}`]: true,
      }),
      eventOnly = this.props.content.fieldTyp === "veranstaltung";

    let manualNodes = this.props.content.fieldBeitraegeAll;

    switch (this.props.content.fieldTyp) {
      case "news": {
        manualNodes = this.props.content.fieldBeitraegeNews;

        break;
      }
      case "person": {
        manualNodes = this.props.content.fieldBeitraegePerson;

        break;
      }
      default: {
        manualNodes = this.props.content.fieldBeitraegeAll;
      }
    }

    return (
      <ThemeWrapper theme={this.props.content.fieldTheme}>
        <section className={sectionClassNames}>
          {this.props.content.fieldHeading && (
            <div className="container">
              <div className="row">
                <div className="col-16 text-center">
                  <h2>{this.props.content.fieldHeading}</h2>
                </div>
              </div>
            </div>
          )}

          {eventOnly && (
            <div className="container">
              <div className="row">
                <ErrorBoundary>
                  <TeaserEventList
                    count={
                      this.props.content.fieldAnzahlDerAnzuzeigendenI
                        ? this.props.content.fieldAnzahlDerAnzuzeigendenI
                        : 100
                    }
                    manualNodes={this.props.content.fieldBeitraegeVeranstaltung}
                    nodesConfig={this.props.content.fieldAutmatischOderManuell}
                    type={this.props.content.fieldTyp}
                    tags={this.props.content.fieldSchlagwort}
                  />
                </ErrorBoundary>
              </div>
            </div>
          )}

          {!eventOnly && this.props.content.fieldKarussel && (
            <ErrorBoundary>
              <ComponentTeaserlistCarousel
                manualNodes={manualNodes}
                id={this.props.content.entityId}
                nodesConfig={this.props.content.fieldAutmatischOderManuell}
                count={
                  this.props.content.fieldAnzahlDerAnzuzeigendenI
                    ? this.props.content.fieldAnzahlDerAnzuzeigendenI
                    : 100
                }
                type={this.props.content.fieldTyp}
                pagerFullPage={
                  this.props.content.fieldPagerAufVollseiten
                    ? this.props.content.entityId
                    : false
                }
                pagerFullPageOverviewLink={
                  this.props.content.fieldLinkZurUebersicht
                }
                tags={this.props.content.fieldSchlagwort}
              />
            </ErrorBoundary>
          )}

          {!eventOnly && !this.props.content.fieldKarussel && (
            <div className="container">
              <div className="row">
                <ErrorBoundary>
                  <TeaserListWrap
                    manualNodes={manualNodes}
                    id={this.props.content.entityId}
                    nodesConfig={this.props.content.fieldAutmatischOderManuell}
                    count={this.props.content.fieldAnzahlDerAnzuzeigendenI}
                    type={this.props.content.fieldTyp}
                    pagerFullPage={
                      this.props.content.fieldPagerAufVollseiten
                        ? this.props.content.entityId
                        : false
                    }
                    pagerFullPageOverviewLink={
                      this.props.content.fieldLinkZurUebersicht
                    }
                    tags={this.props.content.fieldSchlagwort}
                  />
                </ErrorBoundary>
              </div>
            </div>
          )}

          {this.props.content.fieldMehrMeldungenButtonZeig &&
            this.props.content.fieldMehrMeldungenButton.title && (
              <div className="container">
                <div className="row">
                  <div className="col-16 d-md-flex justify-content-md-end">
                    <ErrorBoundary>
                      <Link
                        link={this.props.content.fieldMehrMeldungenButton}
                        className="btn btn-primary more"
                      />
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
            )}
        </section>
      </ThemeWrapper>
    );
  }
}

ParagraphTeaserList.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string,
    fieldKarussel: PropTypes.bool,
    fieldAnzahlDerAnzuzeigendenI: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldMehrMeldungenButtonZeig: PropTypes.bool,
    fieldTyp: PropTypes.oneOf(["news", "person", "veranstaltung", "all"]),
    fieldHeading: PropTypes.string,
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.string,
      })
    ),
    fieldAutmatischOderManuell: PropTypes.oneOf([
      "Automatisch (chronologisch)",
      "Manuell",
    ]),
    fieldLinkZurUebersicht: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.bool,
      }),
    }),
    fieldMehrMeldungenButton: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.bool,
      }),
    }),
    fieldBeitraegeAll: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.oneOfType([
          teaserNewsPropTypes,
          teaserEventPropTypes,
          teaserPersonPropTypes,
          teaserGeneralPropTypes,
          teaserProjektPropTypes,
        ]),
      })
    ),
    fieldBeitraegeVeranstaltung: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserEventPropTypes,
      })
    ),
    fieldBeitraegeNews: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserNewsPropTypes,
      })
    ),
    fieldBeitraegePerson: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserPersonPropTypes,
      })
    ),
  }),
};

export default ParagraphTeaserList;
