import React from "react";
import { useSelector } from "react-redux";
import NodeWrapper from "../components/node-wrapper";
import useNodeQuery from "@hooks/use-node-query";
import newsPageQuery from "./news.graphql";
import PagerFullPage from "@js/general-components/pager-full-page";
import ContentBase from "@general-components/content-base/content-base";
import NewsIntro from "./news-intro";
import ParagraphTeaserList from "@paragraphs/teaserliste/paragraph-teaser-list";

const News = () => {
  /**
   * Hook to get information from Redux Store
   */
  const pagerConfig = useSelector(
    (reduxStore) => reduxStore.appStore.pagerFullPages
  );
  const pagerFullPageContextId = useSelector(
    (reduxStore) => reduxStore.appStore.pagerFullPageContextId
  );

  const { entity, state } = useNodeQuery(newsPageQuery);

  return (
    <NodeWrapper entity={entity} state={state}>
      {entity?.fieldModules && <ContentBase content={entity?.fieldModules} nodeContent={entity} />}

      {pagerFullPageContextId &&
        pagerConfig.filter((config) => config.id === pagerFullPageContextId)
          .length > 0 && (
          <PagerFullPage
            config={
              pagerConfig.filter(
                (config) => config.id === pagerFullPageContextId
              )[0]
            }
            currentId={entity.entityId}
          />
        )}

      <ParagraphTeaserList
        content={{
          fieldAnzahlDerAnzuzeigendenI: 10,
          fieldAutmatischOderManuell: "Automatisch (chronologisch)",
          fieldTheme: { entity: { fieldMachineName: "theme-2-light" } },
          fieldKarussel: true,
          fieldTyp: "news",
          fieldHeading: "Weitere News",
          fieldBeitraegeNews: [],
          fieldSchlagwort: [],
        }}
      />
    </NodeWrapper>
  );
};

News.propTypes = {};

export default News;
