import React from "react";
import PropTypes from "prop-types";

import Eforkbot from "./../../eforkbot/eforkbot";

import ParagraphHeroText from "@paragraphs/hero-text/paragraph-hero-text";
import ParagraphTextAndSpeech from "@paragraphs/text-and-speech/paragraph-text-and-speech";
import ParagraphClickSuggestions from "@paragraphs/click-suggestions/paragraph-click-suggestions";
import ParagraphTeaserList from "@paragraphs/teaserliste/paragraph-teaser-list";
import ParagraphTeaserImage from "@paragraphs/teaser-image/paragraph-teaser-image";
import ParagraphSectionheading from "@paragraphs/section-heading/paragraph-sectionheading";
import ParagraphText from "@paragraphs/text/paragraph-text";
import ParagraphBilderSlider from "@paragraphs/image-image-slider/paragraph-bilder-slider";
import ParagraphNumberFacts from "@paragraphs/number-facts/paragraph-number-facts";
import ParagraphTeaserSlider from "@paragraphs/teaser-slider/paragraph-teaser-slider";
import ParagraphNewsletteranmeldung from "@paragraphs/newsletter/paragraph-newsletteranmeldung";
import ParagraphAkkordionText from "@paragraphs/akkordion-text/paragraph-akkordion-text";
import ParagraphDossier from "@paragraphs/dossier/paragraph-dossier";
import ParagraphMedia from "@paragraphs/media-video-audio/paragraph-media";
import ParagraphPageTitle from "@paragraphs/page-title/paragraph-page-title";
import ParagraphCallToAction from "@paragraphs/cta/paragraph-call-to-action";
import ParagraphExtendedTeaserOverview from "@paragraphs/extended-teaser-list/paragraph-extended-teaser-overview";
import ParagraphCalendarInformation from "@paragraphs/kalender-information/paragraph-calendar-information";
import ParagraphDownload from "@paragraphs/download/paragraph-download";
import ParagraphZitat from "@paragraphs/zitat/paragraph-zitat";
import ParagraphDownloadSchublade from "@paragraphs/download-schublade/paragraph-download-schublade";
import ParagraphTimeline from "@paragraphs/timeline/paragraph-timeline";
import ParagraphKontakt from "@paragraphs/kontakt/paragraph-kontakt";
import ParagraphStrukturierteListe from "@paragraphs/strukturierte-liste/paragraph-strukturierte-liste";
import ParagraphPersonenInformationen from "@paragraphs/personen-informationen/paragraph-personen-informationen";
import ParagraphKalenderEinzelDoppel from "@paragraphs/kalender-einzel-doppel/paragraph-kalender-einzel-doppel";
import ParagraphKalenderAbschnitt from "@paragraphs/kalender-abschnitt/paragraph-kalender-abschnitt";
import ParagraphKalenderMonat from "@paragraphs/kalender-monat/paragraph-kalender-monat";
import ParagraphAudio from "@paragraphs/audio/paragraph-audio";
import ParagraphLogosPartners from "@paragraphs/logos-partners/logos-partners";
import ParagraphTeaserListEvents from "@paragraphs/teaserliste/events/paragraph-teaser-list-events";
import ParagraphFormular from "@paragraphs/webform/paragraph-formular";
import ParagraphStellenangebote from "@paragraphs/stellenangebote/paragraph-stellenangebote";
import ParagraphIntroVideo from "@paragraphs/intro-video/paragraph-intro-video";
import ParagraphTeaserListPages from "@paragraphs/teaserliste/pages/paragraph-teaser-list-pages";
import ParagraphExtendedTeaserOverviewProjects from "@paragraphs/extended-teaser-list/projects/paragraph-extended-teaser-overview-projects";
import ParagraphIntro from "@paragraphs/intro/paragraph-intro";
import ParagraphTeaserHighlight from "@paragraphs/teaser-highlight/paragraph-teaser-highlight";
import ParagraphAppDownloadSection from "@paragraphs/app-download-section/paragraph-app-download-section";
import ParagraphIntroKeyVisual from "@paragraphs/intro-key-visual/paragraph-intro-key-visual";
import ParagraphNewsInfo from "@paragraphs/news-info/paragraph-news-info";

const ParagraphSwitch = ({ paragraphItem, nodeContent }) => {
  switch (paragraphItem?.entityBundle) {
    case "stellenangebote":
      return <ParagraphStellenangebote content={paragraphItem} />;
    case "text_and_speech":
      return <ParagraphTextAndSpeech content={paragraphItem} />;
    case "audio":
      return <ParagraphAudio content={paragraphItem} />;
    case "media":
      return <ParagraphMedia content={paragraphItem} />;
    case "hero_text":
      return <ParagraphHeroText content={paragraphItem} />;
    case "click_suggestions":
      return <ParagraphClickSuggestions content={paragraphItem} />;
    case "teaserliste":
    case "teaserliste_news":
    case "teaserliste_personen":
      return <ParagraphTeaserList content={paragraphItem} />;
    case "teaserliste_events":
      return <ParagraphTeaserListEvents content={paragraphItem} />;
    case "teaserliste_pages":
      return <ParagraphTeaserListPages content={paragraphItem} />;
    case "teaser_image_text":
      return <ParagraphTeaserImage content={paragraphItem} />;
    case "section_heading":
      return <ParagraphSectionheading content={paragraphItem} />;
    case "text":
      return <ParagraphText content={paragraphItem} />;
    case "intro":
      return <ParagraphIntro content={paragraphItem} nodeContent={nodeContent} />;
    case "bilder_slider":
      return <ParagraphBilderSlider content={paragraphItem} />;
    case "zahlen_und_fakten":
      return <ParagraphNumberFacts content={paragraphItem} />;
    case "teaser_slider":
      return <ParagraphTeaserSlider content={paragraphItem} />;
    case "formular":
      return <ParagraphFormular content={item.entity} />;
    case "newsletteranmeldung":
      return <ParagraphNewsletteranmeldung content={paragraphItem} />;
    case "text_akkordion":
      return <ParagraphAkkordionText content={paragraphItem} />;
    case "chatbot":
      return <Eforkbot />;
    case "teaser_sammlung_dossier_":
      return <ParagraphDossier content={paragraphItem} />;
    case "seitentitel":
      return <ParagraphPageTitle content={paragraphItem} />;
    case "call_to_action":
      return <ParagraphCallToAction content={paragraphItem} />;
    case "erweiterte_teaseruebersicht":
    case "teaseruebersicht_events":
    case "teaseruebersicht_personen": {
      const nodes = {};

      if (paragraphItem.fieldManuelleSortierung) {
        nodes.loading = false;
        nodes.nodeQuery = {};
        nodes.nodeQuery.entities = paragraphItem.fieldBeitraege.map(
          (node) => node.entity
        );

        return (
          <ParagraphExtendedTeaserOverview
            content={paragraphItem}
            nodes={nodes}
          />
        );
      }

      return <ParagraphExtendedTeaserOverview content={paragraphItem} />;
    }
    case "teaseruebersicht_projekte": {
      const nodes = {};

      if (paragraphItem.fieldManuelleSortierung) {
        nodes.loading = false;
        nodes.nodeQuery = {};
        nodes.nodeQuery.entities = paragraphItem.fieldBeitraege.map(
          (node) => node.entity
        );

        return (
          <ParagraphExtendedTeaserOverviewProjects
            content={paragraphItem}
            nodes={nodes}
          />
        );
      }

      return (
        <ParagraphExtendedTeaserOverviewProjects content={paragraphItem} />
      );
    }
    case "veranstaltungsinformationen":
      return (
        <ParagraphCalendarInformation
          content={paragraphItem}
          nodeContent={nodeContent}
        />
      );
    case "personeninformationen":
      return (
        <ParagraphPersonenInformationen
          content={paragraphItem}
          nodeContent={nodeContent}
        />
      );
    case "download":
      return (
        <ParagraphDownload content={paragraphItem} nodeContent={nodeContent} />
      );
    case "zitat":
      return (
        <ParagraphZitat content={paragraphItem} nodeContent={nodeContent} />
      );
    case "download_schublade":
      return (
        <ParagraphDownloadSchublade
          content={paragraphItem}
          nodeContent={nodeContent}
        />
      );
    case "timeline":
      return (
        <ParagraphTimeline content={paragraphItem} nodeContent={nodeContent} />
      );
    case "kontakt":
      return (
        <ParagraphKontakt content={paragraphItem} nodeContent={nodeContent} />
      );
    case "strukturierte_liste":
      return (
        <ParagraphStrukturierteListe
          content={paragraphItem}
          nodeContent={nodeContent}
        />
      );
    case "kalender_doppel_einzel_highlight":
      return <ParagraphKalenderEinzelDoppel content={paragraphItem} />;
    case "kalenderabschnitt":
      return <ParagraphKalenderAbschnitt content={paragraphItem} />;
    case "kalendermonatsuebersicht":
      return <ParagraphKalenderMonat content={paragraphItem} />;
    case "logos_partners":
      return <ParagraphLogosPartners content={paragraphItem} />;
    case "intro_video":
      return <ParagraphIntroVideo content={paragraphItem} />;
    case "teaser_highlight":
      return <ParagraphTeaserHighlight content={paragraphItem} />;
    case "app_download_section":
      return <ParagraphAppDownloadSection content={paragraphItem} />;
    case "intro_key_visual":
      return <ParagraphIntroKeyVisual content={paragraphItem} />;
    case "news_info":
      return <ParagraphNewsInfo nodeContent={nodeContent} />;
    default:
      return null;
  }
};

ParagraphSwitch.propTypes = {};

export default ParagraphSwitch;
