import React from "react";
import PropTypes from "prop-types";
import KeyVisual from "@assets/key-visual/key-visual.svg";

const ParagraphIntroKeyVisual = () => {
  return (
    <section className="paragraph paragraph-intro-key-visual">
      <div className="row">
        <div className="col-16 col-md-12 offset-md-4 col-lg-9 offset-lg-7">
          <KeyVisual />
        </div>
      </div>
    </section>
  );
};

ParagraphIntroKeyVisual.propTypes = {};

export default ParagraphIntroKeyVisual;
