import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import humanizeFilemime from "@lib/humanize-filemime";
import { filesize } from "filesize";
import Image from "@general-components/image/image";
import IconDownload from "@assets/icons/icon-download.svg";

const ParagraphDownloadSchublade = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-downloads": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 text-center title-section">
            <h2 className="headline-m">{content.fieldTitel}</h2>
            {content.fieldBeschreibung && (
              <div
                dangerouslySetInnerHTML={{
                  __html: content.fieldBeschreibung.processed,
                }}
              />
            )}
          </div>
        </div>
        {content.fieldDisplayMode === "cards" && (
          <div className="row downloads-cards">
            {content.fieldDownloads.map((download) => (
              <div
                className="col-16 col-sm-8 col-lg-third"
                key={download.entity?.entityId}
              >
                <div className="download-card-item">
                  {download.entity?.fieldVorschaubild && (
                    <Image
                      data={
                        download.entity?.fieldVorschaubild?.entity
                          ?.fieldMediaImage
                      }
                    />
                  )}
                  <span className="d-block headline-s">
                    {download.entity?.entityLabel}
                  </span>
                  {download.entity?.fieldBeschreibung && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: download.entity?.fieldBeschreibung?.processed,
                      }}
                    />
                  )}
                  <a
                    className="btn btn-light"
                    download
                    href={download.entity?.fieldMediaFile?.entity?.url}
                  >
                    Download
                  </a>
                </div>
              </div>
            ))}
          </div>
        )}
        {content.fieldDisplayMode === "list" && (
          <div className="row downloads-list">
            {content.fieldDownloads.map((download) => (
              <div className="col-16" key={download.entity?.entityId}>
                <div className="card download-list-item">
                  <div className="card-body d-flex justify-content-between align-items-center">
                    <div className="d-flex gap-1 flex-column">
                      <span className="d-block headline-s">
                        {download.entity?.entityLabel}
                      </span>
                      <span className="d-flex gap-2">
                        <IconDownload />
                        <span className="d-block">
                          {humanizeFilemime(
                            download.entity?.fieldMediaFile?.entity?.filemime
                          )}{" "}
                          (
                          {filesize(
                            download.entity?.fieldMediaFile?.entity?.filesize
                          )}
                          )
                        </span>
                      </span>
                    </div>

                    <a
                      className="btn btn-light"
                      download
                      href={download.entity?.fieldMediaFile?.entity?.url}
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

ParagraphDownloadSchublade.propTypes = {
  content: PropTypes.shape({
    fieldDatum: PropTypes.shape({
      value: PropTypes.string,
    }),
    fieldRubrikOderKategorie: PropTypes.string,
    fieldTitel: PropTypes.string,
    fieldBeschreibung: PropTypes.shape({
      processed: PropTypes.string,
    }),
    fieldDisplayMode: PropTypes.oneOf(["cards", "list"]),
    fieldDownloads: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityLabel: PropTypes.string,
          fieldVorschaubild: PropTypes.shape({
            entity: PropTypes.shape({
              fieldMediaImage: PropTypes.shape({
                alt: PropTypes.string,
                title: PropTypes.string,
                style: PropTypes.shape({
                  url: PropTypes.string,
                }),
              }),
            }),
          }),
          fieldMediaFile: PropTypes.shape({
            entity: PropTypes.shape({
              url: PropTypes.string,
              filemime: PropTypes.string,
              filesize: PropTypes.number,
            }),
          }),
        }),
      })
    ),
  }),
};

export default ParagraphDownloadSchublade;
