import React from "react";
import { useSelector } from "react-redux";
import NodeWrapper from "../components/node-wrapper";
import useNodeQuery from "@hooks/use-node-query";
import landingPageQuery from "./landingpage.graphql";
import PagerFullPage from "@js/general-components/pager-full-page";
import ContentBase from "@general-components/content-base/content-base";

const Landingpage = () => {
  /**
   * Hook to get information from Redux Store
   */
  const pagerConfig = useSelector(
    (reduxStore) => reduxStore.appStore.pagerFullPages
  );
  const pagerFullPageContextId = useSelector(
    (reduxStore) => reduxStore.appStore.pagerFullPageContextId
  );

  const { entity, state } = useNodeQuery(landingPageQuery);

  return (
    <NodeWrapper entity={entity} state={state}>
      {entity?.fieldModules && (
        <ContentBase content={entity.fieldModules} />
      )}

      {pagerFullPageContextId &&
        pagerConfig.filter((config) => config.id === pagerFullPageContextId)
          .length > 0 && (
          <PagerFullPage
            config={
              pagerConfig.filter(
                (config) => config.id === pagerFullPageContextId
              )[0]
            }
            currentId={entity.entityId}
          />
        )}
    </NodeWrapper>
  );
};

Landingpage.propTypes = {};

export default Landingpage;
