/**
 * Humanize filemime to a more readable format.
 *
 * @param {string} filemime
 * @returns
 */
const humanizeFilemime = (filemime) => {
  const filemimes = {
    "application/pdf": "PDF",
    "application/msword": "DOC",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "DOCX",
    "application/vnd.ms-excel": "XLS",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "XLSX",
    "video/mp4": "MP4",
    "image/jpeg": "JPG",
    "image/png": "PNG",
    "image/gif": "GIF",
    "text/plain": "TXT",
    "application/zip": "ZIP",
  };

  return filemimes[filemime] || filemime;
};

export default humanizeFilemime;
