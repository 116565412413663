import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { graphql } from "@apollo/react-hoc";
import teaserNodeQueryStellenausschreibung from "../../../teaser-base/queries/teaser-node-query-stellenausschreibung.graphql";
import LoadingIndicator from "../../../loading-indicator";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../../../error-boundary";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { self } from "../../../../config";

class ParagraphStellenangebote extends Component {
  componentDidMount() {
    if (window && window.location.hash) {
      let scrollToObject = document.getElementById(
        window.location.hash.replace("#", "")
      );

      if (scrollToObject) {
        window.scroll({
          top:
            scrollToObject.getBoundingClientRect().top +
            document.documentElement.scrollTop -
            200,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-jobs": true,
    });

    if (this.props.data.nodeQuery && this.props.data.nodeQuery.entities) {
      return (
        <section className={sectionClassNames}>
          <div className="container">
            <div className="row">
              <div className="col-16 teaser-list">
                {this.props.data.nodeQuery.entities &&
                  this.props.data.nodeQuery.entities.map(function (
                    item,
                    index
                  ) {
                    return (
                      <article className="card node-teaser-job" key={index}>
                        <div className="card-body d-flex justify-content-between align-items-center">
                          <div>
                            <h3 className="headline-s">{item.title}</h3>
                          </div>
                          <Link
                            to={item.path.alias}
                            className="btn btn-light"
                          >
                            Ansehen
                          </Link>
                        </div>
                      </article>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>
      );
    }

    return <LoadingIndicator />;
  }
}

ParagraphStellenangebote.propTypes = {};

export default graphql(teaserNodeQueryStellenausschreibung, {
  options: (props) => ({
    variables: {
      limit: 50,
    },
  }),
})(ParagraphStellenangebote);
