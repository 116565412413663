import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  PlayFilledAlt,
  PauseFilled,
  Rewind_10,
  VolumeUp,
  VolumeMute,
  VolumeDown,
} from "@carbon/icons-react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

class ParagraphAudio extends Component {
  state = {
    currentTime: 0,
    duration: 0,
  };

  timeUpdateListener = (e) => {
    this.setState({
      currentTime: e.target.currentTime,
      duration: e.target.duration,
    });
  };

  constructor(props) {
    super(props);

    this.player = React.createRef();
  }

  getTime = (time) => {
    if (!isNaN(time)) {
      return (
        Math.floor(time / 60) +
        "min " +
        ("0" + Math.floor(time % 60)).slice(-2) +
        "sec"
      );
    }

    return null;
  };

  goBack = (seconds) => {
    this.player.currentTime = this.state.currentTime - seconds;
  };

  play = () => {
    this.player.play();
  };

  pause = () => {
    this.player.pause();
  };

  minusVolume = () => {
    this.player.volume = this.player.volume - 0.2;
  };

  plusVolume = () => {
    this.player.volume = this.player.volume + 0.2;
  };

  mute = () => {
    this.player.volume = 0.0;
  };

  componentDidMount() {
    this.player.addEventListener("timeupdate", (e) =>
      this.timeUpdateListener(e)
    );

    this.setState({
      currentTime: this.player.currentTime,
      duration: this.player.duration,
    });
  }

  componentWillUnmount() {
    this.player.removeEventListener("timeupdate", (e) =>
      this.timeUpdateListener(e)
    );
  }

  render() {
    const sectionClassNames = classNames({
        "paragraph paragraph-audio": true,
      }),
      { content } = this.props,
      currentTime = this.getTime(this.state.currentTime),
      duration = this.getTime(this.state.duration);

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            {content.fieldAudioDatei.entity.fieldBildAudio !== null && (
              <div className="col-sm-12 col-md-4 image-section">
                <img
                  src={content.fieldAudioDatei.entity.fieldBildAudio.url}
                  alt=""
                />
              </div>
            )}
            <div className="col-sm-12 audio-section">
              {Boolean(content.fieldTitleAudio) && (
                <h2>{content.fieldTitleAudio}</h2>
              )}
              <div className="audio">
                <audio
                  src={
                    content.fieldAudioDatei.entity.fieldMediaAudioFile.entity
                      .url
                  }
                  aria-controls="audio-controls"
                  ref={(ref) => (this.player = ref)}
                ></audio>
                <div className="time">
                  <div className="current">{currentTime}</div>
                  <div className="duration">{duration}</div>
                </div>
                <div className="progress-bar">
                  <div className="bar">
                    <div
                      className="fill"
                      style={{
                        width: `${
                          (100 / this.state.duration) * this.state.currentTime
                        }%`,
                      }}
                    />
                  </div>
                </div>
                <div id="audio-controls" className="button">
                  <div className="function">
                    <button tabIndex={0} onClick={this.play}>
                      <PlayFilledAlt />
                    </button>
                    <button tabIndex={0} onClick={this.pause}>
                      <PauseFilled />
                    </button>
                    <button tabIndex={0} onClick={() => this.goBack(10)}>
                      <Rewind_10 />
                    </button>
                  </div>
                  <div className="volume">
                    <button tabIndex={0} onClick={this.plusVolume}>
                      <VolumeUp />
                    </button>
                    <button tabIndex={0} onClick={this.minusVolume}>
                      <VolumeDown />
                    </button>
                    <button tabIndex={0} onClick={this.mute}>
                      <VolumeMute />
                    </button>
                  </div>
                </div>
              </div>
              {Boolean(content.fieldAudioDatei.entity.fieldTextKopf) &&
                Boolean(content.fieldAudioDatei.entity.fieldTextAudio) && (
                  <div className="info">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-12">
                        <Accordion allowZeroExpanded>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <div className="top">
                                  <div className="text">
                                    {
                                      content.fieldAudioDatei.entity
                                        .fieldTextKopf
                                    }
                                  </div>
                                  <div className="icon"></div>
                                </div>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    content.fieldAudioDatei.entity
                                      .fieldTextAudio.processed,
                                }}
                              />
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphAudio.propTypes = {
  content: PropTypes.shape({
    fieldTitleAudio: PropTypes.string,
    fieldAudioDatei: PropTypes.shape({
      entity: PropTypes.shape({
        fieldTextAudio: PropTypes.shape({
          processed: PropTypes.string,
        }),
        fieldTextKopf: PropTypes.string,
        fieldMediaAudioFile: PropTypes.shape({
          entity: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
        fieldBildAudio: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      }),
    }),
  }),
};

export default ParagraphAudio;
