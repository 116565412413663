import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const TextBox = ({ html, titleId }) => {
  if (!html) return;
  
  return (
    <section className="paragraph paragraph-text-box">
      <div className="container">
        <div className="card theme-1">
          <div className="card-body">
            <div className="row">
              <div className="col-16 col-lg-12 offset-lg-2 text">
                <h2 className="headline-m">
                  <FormattedMessage id={titleId} />
                </h2>
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

TextBox.propTypes = {
  html: PropTypes.string,
  titleId: PropTypes.string,
};

export default TextBox;
