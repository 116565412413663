import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import LoadingIndicator from "@general-components/loading-indicator";

const NodeWrapper = ({ entity, state, children }) => {
  const published = entity.status ? "node-published" : "node-unpublished";

  if (state === "loading") return <LoadingIndicator />;

  return (
    <article
      className={`node-${entity.entityBundle} node-${entity.entityId} node-full-page ${published}`}
    >
      <Helmet>
        <title>{`Base Site | ${entity.entityLabel}`}</title>
      </Helmet>

      {children}
    </article>
  );
};

NodeWrapper.propTypes = {
  entity: PropTypes.object,
  state: PropTypes.string,
  children: PropTypes.node,
};

export default NodeWrapper;
