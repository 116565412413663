import React from "react";
import Error from "@system/error";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

const RouteError = () => {
  const error = useRouteError();
  return <Error error={error} errorResponse={isRouteErrorResponse(error)} />;
};

export default RouteError;
