import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Close } from "@carbon/icons-react";

class ComponentExtendedTeaserOverviewFilterText extends Component {
  state = {};

  render() {
    return (
      <section className="filter-text">
        <div className="container">
          <div className="row">
            <div className="col-16">
              {this.props.items.map((item, index) =>
                (() => {
                  switch (item.entity.entityBundle) {
                    case "filtertext_text":
                      return (
                        <p key={index}>{item.entity.fieldFilterTextText}</p>
                      );
                    case "filtertext_filter":
                      return (
                        <div
                          className={classNames({
                            filter: true,
                            active:
                              this.props.activeFilter &&
                              this.props.activeFilter.entityId ===
                                item.entity.fieldFilter.entity.entityId,
                          })}
                          key={index}
                          onClick={() =>
                            this.props.changeActiveFilter(
                              item.entity.fieldFilter.entity
                            )
                          }
                        >
                          <span>
                            {item.entity.fieldFilter.entity.entityLabel}
                            {this.props.activeFilter &&
                              this.props.activeFilter.entityId ===
                                item.entity.fieldFilter.entity.entityId && (
                                <Close />
                              )}
                          </span>
                        </div>
                      );
                    default:
                      return null;
                  }
                })()
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ComponentExtendedTeaserOverviewFilterText.propTypes = {
  activeFilter: PropTypes.shape({
    entityId: PropTypes.string
  }),
  changeActiveFilter: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    entity: PropTypes.shape({
      entityBundle: PropTypes.oneOf(["filtertext_text", "filtertext_filter"]),
      fieldFilterTextText: PropTypes.string,
      fieldFilter: PropTypes.shape({
        entity: PropTypes.shape({
          entityLabel: PropTypes.string,
          entityId: PropTypes.string
        })
      })
    })
  })),
};

export default ComponentExtendedTeaserOverviewFilterText;
