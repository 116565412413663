import React, { Component } from "react";
import PropTypes from "prop-types";
import IconQuote from "@assets/icons/icon-quote.svg";
import useQuotationMarks from "@hooks/use-quotation-marks";

const ParagraphZitat = ({ content }) => {
  const quote = useQuotationMarks(content.fieldZitat);
  const source = content.fieldQuelle;

  return (
    <section className="paragraph paragraph-quote">
      <div className="container">
        <div className="row">
          <div className="col-16 col-lg-10 offset-lg-3">
            <div className="d-flex flex-column flex-sm-row">
              <IconQuote />
              <blockquote className="text">
                {quote}
                <cite>{source}</cite>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphZitat.propTypes = {
  content: PropTypes.shape({
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.object,
      }),
    }),
    fieldZitat: PropTypes.string.isRequired,
    fieldQuelle: PropTypes.string.isRequired,
  }),
};

ParagraphZitat.defaultProps = {
  content: {
    fieldImage: null,
  },
};

export default ParagraphZitat;
