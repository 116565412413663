import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSliderContext } from "./context";

export const Slider = ({ children }) => {
  const { emblaRef, emblaOptions, emblaApi, canScrollPrev, canScrollNext } =
    useSliderContext();

  const style = {};
  if (emblaOptions.gap) {
    style["--embla-gap"] = emblaOptions.gap;
  }
  if (emblaOptions.width) {
    style["--embla-width"] = emblaOptions.width;
  }

  const handlePrev = () => emblaApi.scrollPrev();
  const handleNext = () => emblaApi.scrollNext();

  return (
    <>
      {canScrollPrev && (
        <div
          className="embla__click-overlay prev"
          role="button"
          onClick={handlePrev}
        />
      )}
      <div className="embla" ref={emblaRef} style={style}>
        <div className="embla__container">{children}</div>
      </div>
      {canScrollNext && (
        <div
          className="embla__click-overlay next"
          role="button"
          onClick={handleNext}
        />
      )}
    </>
  );
};

export const Slide = ({ children }) => {
  return <div className="embla__slide">{children}</div>;
};

export const SliderControls = () => {
  const { emblaApi, canScrollPrev, canScrollNext } = useSliderContext();

  const handlePrev = () => emblaApi.scrollPrev();
  const handleNext = () => emblaApi.scrollNext();

  if (!canScrollPrev && !canScrollNext) {
    return null;
  }

  return (
    <div className="slider-controls">
      <button
        className="prev btn btn-arrow"
        onClick={handlePrev}
        disabled={!canScrollPrev}
      >
        ←<span className="sr-only">Previous</span>
      </button>
      <button
        className="next btn btn-arrow"
        onClick={handleNext}
        disabled={!canScrollNext}
      >
        →<span className="sr-only">Next</span>
      </button>
    </div>
  );
};

export const SliderDots = () => {
  const { emblaApi, emblaOptions } = useSliderContext();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const [reducedScrollSnaps, setReducedScrollSnaps] = useState([]);

  const onInit = useCallback((emblaApi) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  const onSelectDot = useCallback(
    (index) => {
      if (!emblaApi) return;
      emblaApi.scrollTo(index);
    },
    [emblaApi]
  );

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit).on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi]);

  if (emblaOptions.loop && !!scrollSnaps?.length) {
    return null;
  }

  return (
    <div className="embla__dots">
      {scrollSnaps.map((_, index) => (
        <button
          key={index}
          className={`embla__dot ${
            index === selectedIndex ? "is-selected" : ""
          }`}
          onClick={() => onSelectDot(index)}
        />
      ))}
    </div>
  );
};

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  options: PropTypes.shape({
    loop: PropTypes.bool,
  }),
};
