import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Download } from "@carbon/icons-react";
import Image from "../../../image/image";
import {connect} from "react-redux";

const mapStateToProps = (reduxStore) => ({
  microsite: reduxStore.appStore.microsite,
});
class ParagraphDownload extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-download": true,
    });
    if (this.props.microsite){
      return (
        <section className={sectionClassNames}>
          <div className="container">
            <div className="row">
              <div className="col-16 d-flex justify-content-center">
                <a
                  className="download-link"
                  href={
                    this.props.content.fieldDatei.entity.fieldMediaFile.entity
                      .url
                  }
                  download={true}
                >
                  {this.props.content.fieldDatei.entity.fieldBeschreibung && (
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html:
                        this.props.content.fieldDatei.entity.fieldBeschreibung
                          .processed,
                      }}
                    />
                  )}
                  {this.props.content.fieldDatei.entity.fieldBeschreibung === null ? (
                    <div className="text">
                      {this.props.content.fieldDatei.entity.entityLabel.replace(/\.[^/.]+$/, '')}
                    </div>
                  ) : null}
                </a>
              </div>
            </div>
          </div>
        </section>
      );
    }else{
      return (
        <section className={sectionClassNames}>
          <div className="container">
            <div className="row">
              <div className="col-16 col-md-15 offset-md-1">
                <div className="row">
                  {this.props.content.fieldDatei.entity.fieldVorschaubild !==
                    null && (
                      <div className="col-2">
                        <Image
                          data={
                            this.props.content.fieldDatei.entity.fieldVorschaubild
                              .entity.fieldMediaImage
                          }
                        />
                      </div>
                    )}
                  <div
                    className={classNames({
                      "col-10":
                        this.props.content.fieldDatei.entity.fieldVorschaubild ===
                        null,
                      "col-8":
                        this.props.content.fieldDatei.entity.fieldVorschaubild !==
                        null,
                    })}
                  >
                    <h3>{this.props.content.fieldDatei.entity.entityLabel}</h3>
                    {this.props.content.fieldDatei.entity.fieldBeschreibung && (
                      <div
                        className="text"
                        dangerouslySetInnerHTML={{
                          __html:
                          this.props.content.fieldDatei.entity.fieldBeschreibung
                            .processed,
                        }}
                      />
                    )}
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <a
                      className="btn btn-primary"
                      href={
                        this.props.content.fieldDatei.entity.fieldMediaFile.entity
                          .url
                      }
                      download={true}
                    >
                      <Download size={24} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }

  }
}

ParagraphDownload.propTypes = {
  content: PropTypes.shape({
    fieldDatei: PropTypes.shape({
      entity: PropTypes.shape({
        fieldVorschaubild: PropTypes.shape({
          entity: PropTypes.shape({
            fieldMediaImage: PropTypes.shape({
              alt: PropTypes.string,
              title: PropTypes.string,
            }),
          }),
        }),
        entityLabel: PropTypes.string.isRequired,
        fieldBeschreibung: PropTypes.shape({
          processed: PropTypes.string,
        }),
        fieldMediaFile: PropTypes.shape({
          entity: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
  }),
};

export default connect(mapStateToProps)(ParagraphDownload);
