import React from "react";
import { useSelector } from "react-redux";

import eventPageQuery from "./event.graphql";
import { useLocation } from "react-router-dom";
import useNodeQuery from "@hooks/use-node-query";
import NodeWrapper from "@nodes/components/node-wrapper";
import PagerFullPage from "@js/general-components/pager-full-page";
import ContentBase from "@general-components/content-base/content-base";

const Event = () => {
  /**
   * Hook to get information from Redux Store
   */
  const pagerConfig = useSelector(
    (reduxStore) => reduxStore.appStore.pagerFullPages
  );
  const location = useLocation();
  const queryStrings = location.search;

  const { entity, state } = useNodeQuery(eventPageQuery);

  return (
    <NodeWrapper entity={entity} state={state}>
      <ContentBase content={entity.fieldModules} nodeContent={entity} />

      {queryStrings.p &&
        pagerConfig.filter((config) => config.id === queryStrings.p).length >
          0 && (
          <PagerFullPage
            config={
              pagerConfig.filter((config) => config.id === queryStrings.p)[0]
            }
            currentId={entity.entityId}
          />
        )}
    </NodeWrapper>
  );
};

Event.propTypes = {};

export default Event;
