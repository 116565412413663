import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useSelector } from "react-redux";
import ThemeWrapper from "@general-components/theme-wrapper/theme-wrapper";

/**
 * A module representing a text paragraph.
 */
const ParagraphText = ({ content }) => {
  const microsite = useSelector((state) => state.appStore.microsite);

  const sectionClassNames = classNames({
    "paragraph paragraph-text": true,
    "background-color": content.fieldFarbigHinterlegen,
    [content.fieldTextDisplayMode]: content.fieldTextDisplayMode,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div
            className={classNames({
              "col-wrapper col-16": true,
              "col-md-12 offset-md-2 col-xl-8 offset-xl-4": microsite,
              "col-lg-10 offset-lg-3": !microsite,
            })}
          >
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: content.fieldText.processed,
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphText.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldFarbigHinterlegen: PropTypes.bool,
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
    }).isRequired,
    fieldTextDisplayMode: PropTypes.string,
    fieldTheme: PropTypes.object,
  }).isRequired,
};

export default ParagraphText;
