import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ComponentBildSlider from "./component-bild-slider";
import { useSelector } from "react-redux";
import Image from "../../../image/image";
import ErrorBoundary from "@js/error-boundary";
import KeyVisualMask from "./key-visual-mask";
import ThemeWrapper from "@general-components/theme-wrapper/theme-wrapper";
import SliderProvider from "@general-components/slider/context";
import { Slide, Slider } from "@general-components/slider/slider";
import BildSliderGGB from "./bild-slider-ggb";

const ParagraphBilderSlider = ({ content }) => {
  const microsite = useSelector((state) => state.appStore.microsite);

  const sectionClassNames = classNames({
    "paragraph paragraph-bilder-slider": true,
    [`format-${content.fieldFormat}`]: true,
  });

  const images = content.fieldBilderWCaption.map((image) => {
    return {
      image: image.entity.fieldMediaImage,
      caption:
        image.customCaption ||
        image.entity.fieldBildunterschrift ||
        content.fieldBildunterschrift,
      credit: image.entity.fieldCredit,
    };
  });

  const mode = content.fieldMode || "default";

  if (microsite === "ggb") {
    return <BildSliderGGB content={content} />;
  }

  return (
    <ThemeWrapper theme={content.fieldTheme}>
      <section className={`paragraph paragraph-image-slider mode-${mode}`}>
        {mode === "key_visual" && images.length === 1 && (
          <div className="container">
            <KeyVisualMask imagePath={images[0].image.styleTeaserSingle?.url} />
          </div>
        )}

        {mode === "default" && (
          <div className="container">
            <div className="row">
              <div
                className={classNames({
                  "col-16 col-lg-10 offset-lg-3":
                    content.fieldFormat === "center",
                  "col-16": content.fieldFormat === "full",
                  "col-16 col-lg-10": content.fieldFormat === "left",
                  "col-16 col-lg-10 offset-lg-6":
                    content.fieldFormat === "right",
                })}
              >
                {images.length === 1 && (
                  <>
                    <Image data={images[0].image} />
                    <p>
                      {images[0].caption && (
                        <span className="caption">{images[0].caption} </span>
                      )}
                      {images[0].credit && (
                        <span>&copy; {images[0].credit}</span>
                      )}
                    </p>
                  </>
                )}
                {images.length > 1 && (
                  <SliderProvider
                    options={{ loop: false, gap: "var(--space-sm)" }}
                  >
                    <Slider>
                      {images.map((image, index) => (
                        <Slide key={index}>
                          <Image data={image.image} />
                          <p>
                            {image.caption && (
                              <span className="caption">{image.caption} </span>
                            )}
                            {image.credit && <span>&copy; {image.credit}</span>}
                          </p>
                        </Slide>
                      ))}
                    </Slider>
                  </SliderProvider>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    </ThemeWrapper>
  );
};
ParagraphBilderSlider.propTypes = {
  content: PropTypes.shape({
    fieldBildunterschrift: PropTypes.string,
    fieldBilderWCaption: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldMediaImage: PropTypes.shape({
            alt: PropTypes.string,
            title: PropTypes.string,
            style: PropTypes.shape({
              url: PropTypes.string.isRequired,
            }),
          }),
        }),
      })
    ),
    fieldFormat: PropTypes.oneOf(["center", "left", "right", "full"]),
    fieldMode: PropTypes.oneOf(["default", "key_visual"]),
  }),
};

export default ParagraphBilderSlider;
