import React from "react";
import { useSelector } from "react-redux";
import NodeWrapper from "../components/node-wrapper";
import useNodeQuery from "@hooks/use-node-query";
import jobPageQuery from "./job.graphql";
import ParagraphKontakt from "@paragraphs/kontakt/paragraph-kontakt";
import TextBox from "./components/text-box";
import ParagraphSwitch from "@general-components/content-base/paragraph-switch";
import ErrorBoundary from "@js/error-boundary";
import { FormattedMessage } from "react-intl";
import ButtonApply from "./components/button-apply";

const Job = () => {
  const { entity, state } = useNodeQuery(jobPageQuery);

  const modules = [
    {
      entity: {
        entityBundle: "intro",
        fieldTheme: {
          entity: {
            fieldMachineName: "theme-1",
          },
        },
        fieldModules: [
          {
            entity: {
              entityBundle: "seitentitel",
              fieldHeading: entity?.entityLabel,
            },
          },
        ],
      },
    },
    {
      entity: {
        entityBundle: "hero_text",
        fieldText: entity?.fieldTeasertext,
      },
    },
  ];

  return (
    <NodeWrapper entity={entity} state={state}>
      {modules.map((item, index) => (
        <ErrorBoundary key={index}>
          <ParagraphSwitch paragraphItem={item.entity} />
        </ErrorBoundary>
      ))}
      {entity.fieldTeasertext && <ButtonApply />}
      {entity.body && (
        <section className="body-text">
          <div className="container">
            <div className="row">
              <div
                className="col-16 col-lg-10 offset-lg-3 text"
                dangerouslySetInnerHTML={{ __html: entity.body?.processed }}
              />
            </div>
          </div>
        </section>
      )}
      <TextBox html={entity.fieldTasks?.processed} titleId="job.tasks" />
      <TextBox
        html={entity.fieldRequirements?.processed}
        titleId="job.requirements"
      />
      <TextBox html={entity.fieldBenefits?.processed} titleId="job.benefits" />
      <ButtonApply />
      {entity.fieldContact && (
        <>
          <section className="paragraph headline-questions">
            <div className="container">
              <div className="text-center">
                <h2 className="headline-xl">Noch Fragen?</h2>
              </div>
            </div>
          </section>
          <ParagraphKontakt
            content={{ fieldPersonen: [entity.fieldContact] }}
          />
        </>
      )}
    </NodeWrapper>
  );
};

Job.propTypes = {};

export default Job;
