import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";

import teaserNodeQueryEventTagFiltered from "../../../../teaser-base/queries/teaser-node-query-event-tag-filtered.graphql";
import LoadingIndicator from "../../../../loading-indicator";
import TeaserEvent, {
  teaserEventPropTypes,
} from "../../../../teaser-base/event/teaser-event";

moment.locale("de");

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  currentLanguage: reduxStore.i18n.currentLanguage,
});

const TeaserEventList = (props) => (
  <>
    {props.nodesConfig === "Manuell" ? (
      <>
        {props.manualNodes.map((item, index) => (
          <TeaserEvent key={index} item={item.entity} />
        ))}
      </>
    ) : (
      <>
        {props.nodes.nodeQuery &&
        props.nodes.nodeQuery.entities &&
        props.nodes.nodeQuery.entities.length > 0 ? (
          <>
            {props.nodes.nodeQuery.entities.map((item, index) => (
              <TeaserEvent key={index} item={item} />
            ))}
          </>
        ) : (
          <LoadingIndicator />
        )}
      </>
    )}
  </>
);

TeaserEventList.propTypes = {
  count: PropTypes.number,
  manualNodes: PropTypes.arrayOf(
    PropTypes.shape({
      entity: teaserEventPropTypes,
    })
  ),
  nodesConfig: PropTypes.oneOf(["Automatisch (chronologisch)", "Manuell"]),
  type: PropTypes.oneOf(["veranstaltung"]),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      targetId: PropTypes.string,
    })
  ),
  location: PropTypes.object.isRequired,
  nodes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(graphql(teaserNodeQueryEventTagFiltered, {
  name: "nodes",
  skip: (props) => props.nodesConfig === "Manuell",
  options: (props) => ({
    variables: {
      date: moment().format("YYYY-MM-DD"),
      limit: props.count,
      type: [props.type],
      tags: props.tags.map((item) => item.targetId.toString()),
      filterTagEnabled: props.tags.length > 0,
      language: props.currentLanguage.toUpperCase(),
    },
  }),
})(TeaserEventList));
