import React from "react";
import { useSelector } from "react-redux";
import NodeWrapper from "../components/node-wrapper";
import useNodeQuery from "@hooks/use-node-query";
import projektPageQuery from "./projekt.graphql";
import PagerFullPage from "@js/general-components/pager-full-page";
import ContentBase from "@general-components/content-base/content-base";

const Projekt = () => {
  /**
   * Hook to get information from Redux Store
   */
  const pagerConfig = useSelector(
    (reduxStore) => reduxStore.appStore.pagerFullPages
  );
  const pagerFullPageContextId = useSelector(
    (reduxStore) => reduxStore.appStore.pagerFullPageContextId
  );

  const { entity, state } = useNodeQuery(projektPageQuery);

  const modules = [
    {
      entity: {
        entityBundle: "intro",
        fieldTheme: {
          entity: {
            fieldMachineName: "theme-2",
          },
        },
        fieldModules: [
          {
            entity: {
              entityBundle: "seitentitel",
              fieldHeading: entity?.entityLabel,
            },
          },
        ],
      },
    },
    ...(entity?.fieldModules || []),
  ];

  return (
    <NodeWrapper entity={entity} state={state}>
      <ContentBase content={modules} />

      {pagerFullPageContextId &&
        pagerConfig.filter((config) => config.id === pagerFullPageContextId)
          .length > 0 && (
          <PagerFullPage
            config={
              pagerConfig.filter(
                (config) => config.id === pagerFullPageContextId
              )[0]
            }
            currentId={entity.entityId}
          />
        )}
    </NodeWrapper>
  );
};

Projekt.propTypes = {};

export default Projekt;
