import React from "react";
import PropTypes from "prop-types";
import ErrorBoundary from "@js/error-boundary";
import DateTime from "@general-components/date-time/date-time";

const ParagraphNewsInfo = ({ nodeContent }) => {
  return (
    <section className="paragraph paragraph-news-info">
      <ErrorBoundary>
        <DateTime
          value={nodeContent.publishedAt?.value}
          mode="long"
          granularity="date"
          className="small"
        />
      </ErrorBoundary>
      <h1>{nodeContent.title}</h1>
      {nodeContent.fieldTeasertext && (
        <div
          className="text text-l"
          dangerouslySetInnerHTML={{
            __html: nodeContent.fieldTeasertext?.processed,
          }}
        />
      )}
    </section>
  );
};

ParagraphNewsInfo.propTypes = {
  nodeContent: PropTypes.shape({
    publishedAt: PropTypes.object,
    title: PropTypes.string,
    fieldTeasertext: PropTypes.object,
  }),
};

export default ParagraphNewsInfo;
